import { notification } from "antd";

function ueNotification(status: number): boolean {
    if ((status > 110 && status < 210 ) || status >= 360) {
        notification.open({
            message: 'A 3D environment has been generated in Unreal Engine. Please click the quick generate button to view.',
            placement: "topRight",
            showProgress: true
        });
        return true;
    } else if (status === 0) {
        notification.open({
            message: 'Please generate a 3D model first',
            placement: "topRight",
            showProgress: true
        });
        return true;
    } else if(status === 300 || status === 230){
        notification.open({
            message: 'Generating UE scene, please refresh the history periodically to check the current task status.',
            placement: "topRight",
            showProgress: true
        });
        return true;
    }else {
        return false;
    }
};

function hudiniNotification(status: number): boolean {
    if (status >= 210) {
        notification.open({
            message: 'The current map has already generated a 3D preview. Please choose another map!',
            placement: "topRight",
            showProgress: true
        });
        return true;
    } else {
        return false;
    }
}

function reconnectUESceneNotice(callback: Function, notifiKey: string): void {
    notification.open({
        message: 'Unreal Engine',
        description: 'An abnormality in your network connection is detected.',
        duration: 0,  // 设置为 0 表示通知不会自动关闭
        placement: "bottomRight",
        key: notifiKey,  // 给通知指定一个唯一的 key
        btn: callback()
    });
}

export {
    ueNotification,
    hudiniNotification,
    reconnectUESceneNotice
}
