import param from "../config/index.ts";
class MapWorker {
    protected working:boolean = false;
    protected workRequested:boolean = false;
    protected worker:Worker | null = null;

    private render;
    private elapsedTimeHistory:number[] = [];
    private generate:()=>void;
    private messageHandler?: (event: MessageEvent) => void;
    private errorHandler?: (event: ErrorEvent) => void;


    // @ts-ignore
    constructor(render ,generate:()=>void) {
        this.render = render;
        this.generate = generate;
        if(this.worker) return;
        this.worker = new Worker(
            new URL("./worker.ts", import.meta.url), 
            { type: "module" }
        );

        this.worker.addEventListener('messageerror', this.handleMsgError.bind(this));
        this.worker.addEventListener('message', this.handleMsg.bind(this));
    }

    public getWorker(){
        return this.worker;
    }

    public isWorking(){
        return this.working;
    }

    public startWork(){
        this.working = true;
    }

    public endWork(){
        this.working = false;
    }

    public requestWork(){
        this.workRequested = true;
    }

    public resetWorkState(){
        this.working = false;
        this.workRequested = false;
    }

    // @ts-ignore
    private handleMsg  (evt) {

        this.working = false;
        let { elapsed, numRiverTriangles, quad_elements_buffer, a_quad_em_buffer, a_river_xyuv_buffer } = evt.data;
        this.elapsedTimeHistory.push(elapsed | 0);
        if (this.elapsedTimeHistory.length > 10) { this.elapsedTimeHistory.splice(0, 1); }
        const timingDiv = document.getElementById('timing');
        if (timingDiv) { timingDiv.innerText = `${this.elapsedTimeHistory.join(' ')} milliseconds`; }
        this.render.geometryBuffers.quad_elements = new Int32Array(quad_elements_buffer);
        this.render.geometryBuffers.a_quad_em = new Float32Array(a_quad_em_buffer);
        this.render.geometryBuffers.a_river_xyuv = new Float32Array(a_river_xyuv_buffer);
        this.render.numRiverTriangles = numRiverTriangles;
        // update map data 
        this.render.updateMap();
        this.render.updateView(param.render);
        if (this.workRequested) {
            requestAnimationFrame(() => {
                this.workRequested = false;
                this.generate();
            });
        }
    }
    // @ts-ignore
    private handleMsgError (evt)  {
        console.log("WORKER ERROR", event);
    }

    public dispose(): void {
        try {
            // 移除事件监听
            if (this.messageHandler) {
                this.worker?.removeEventListener('message', this.messageHandler);
            }
            if (this.errorHandler) {
                this.worker?.removeEventListener('error', this.errorHandler);
            }

            // 终止 Worker
            this.worker?.terminate();

            // 重置状态
            this.resetWorkState();

            // 清空引用
            this.messageHandler = undefined;
            this.errorHandler = undefined;

        } catch (error) {
            console.error('Error disposing MapWorker:', error);
        }
    }
}

export default MapWorker;