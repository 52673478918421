import { useState } from "react";
import "./index.css";
import cx from "classnames";
import { MapgenStyleItem } from "@/types";

const terrainImgs: MapgenStyleItem[] = [
  { src: "/image/Forest-Wetland.png", name: "Forest Wetland" },
  { src: "/image/Snow-Mountain.png", name: "Snow Mountain" },
  { src: "/image/Gobi-Desert.png", name: "Gobi Desert" },
];

const townImgs: MapgenStyleItem[] = [
  { src: "/image/Water-village.png", name: "Water Village" },
  { src: "/image/Medieval-village1.png", name: "Medieval Village" },
  { src: "/image/Wooden-House.png", name: "Wooden House" },
  { src: "/image/Farm-House.png", name: "Farm House" },
];

function PreparedScene({
  serialNumber,
  clickImgHandler,
}: {
  serialNumber: number;
  clickImgHandler: (terrain: string, town: string) => void;
}) {
  const [selectedTerrain, setSelectedTerrain] = useState("Forest Wetland");
  const [selectedTown, setSelectedTown] = useState("Water Village");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clickTerrain = (name: string) => {
    setSelectedTerrain(name);
  }

  const clickTown = (name: string) => {
    setSelectedTown(name);
  }

  const styleCardRender = (RenderList: MapgenStyleItem[], selected: string, selectHandler: (name: string) => void) => {
    return RenderList.map((img) => (
      <div
        className={cx(
          "cursor-pointer items-center  card-item mr-4 mt-4 flex flex-col border-2 border-transparent",
          {
            "!border-white": img.name === selected,
          }
        )}
        onClick={() => selectHandler?.(img.name)}
        key={img.name}
      >
        <img
          src={img.src}
          alt={img.name}
          width={120}
          key={img.name}
        />
        <div className="tcenter mt-4">{img.name}</div>
      </div>
    ));
  }

  return (
    <div className="generate-style  items-center rounded-md p-5">
      <div className="flex items-center text-lg mr-5 relative">
        <span className="serial-number mr-2">{serialNumber}</span>
        <span className="mr-2">Choose style</span>
      </div>
      <div className="items-center justify-between mt-5">
        <div className="pt-2">
          <div>Biome</div>
          <div className="card-wrap flex ">
            {styleCardRender(terrainImgs, selectedTerrain, clickTerrain)}
          </div>
        </div>

        <div className="pt-2">
          <div>Style of town</div>
          <div className="card-wrap flex">
            {styleCardRender(townImgs, selectedTown, clickTown)}
          </div>
        </div>

        <div className="relative " style={{ height: 60 }}>
          <button
            className={`button-3d-preview rounded-md text-base mt-6 absolute bottom-0 right-0 ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
            onClick={async () => {
              setIsLoading(true);
              await clickImgHandler(selectedTerrain, selectedTown);
              setIsLoading(false);
            }}
          >
            {isLoading ? "Generating" : "Generate 3D preview"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PreparedScene;
