import { GenerateUEProgress } from "@/lib/utils";
import { ProgerssProps } from "@/types";

function UEProgress({loading, status, genScene, btnStyle,value, total_time, taskAhead, renderAhead, isClosed}: ProgerssProps) {
    if(isClosed){        
        return (
            <div>
                Closing...
            </div>
        )
    }
    // console.log("UEProgress: ", loading, status, taskAhead, renderAhead, isClosed);
    
    if (!loading) {
        if (status >= 360) {
            return (
                <button
                    className=" rounded-sm text-black px-2 py-1 bg-white hover:bg-gray-200"
                    onClick={genScene}
                    style={btnStyle}
                >Generate scenes quickly</button>
            )
        }

        return <span>Click "Generate Environment" to generate the scene</span>
    }

    return (
        <div className="mx-2 flex flex-col items-center space-y-2 w-[30rem]">
            <progress value={value} max="100" className=' w-60 model-progress'></progress>
            <span>{GenerateUEProgress(status, taskAhead, renderAhead)}, Total Time:{total_time}s</span>
        </div>
    );
};

export default UEProgress;