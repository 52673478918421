import { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RandomImage from "@/components/RandomImage/RandomImage";
import TerrainSize from "@/components/TerrainSize/TerrainSize";
import TextToImage from "@/components/TextToImage/TextToImage";
import DrawingBoard from "@/components/DrawingBoard/DrawingBoard";
import { DrawBoardRef } from "@/types";
import UploadMap from "@/components/UploadMap/UploadMap";
import Warning from "@/components/Warning/Warning";
import GenerateStyle from "@/components/GenerateStyle/GenerateStyle";
import { v4 as uuidv4 } from 'uuid';
import { createTask, randomMap } from "@/service/taskService";
import { cropImage, loadImageFromBase64, loadReader } from "@/lib/utils";
import { defaultImgs } from "@/lib/data";
import backIcon from "@/assets/back.png";
import "./ColorMap.css";
import { Mask } from "@/components/Mask/Mask";


function ColorMap() {
    const location = useLocation();
    const navigate = useNavigate();
    const param = location.state ?? "template";
    const drawBoardRef = useRef<DrawBoardRef>(null);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const [showUpload, setShowUpload] = useState<boolean>(false);
    const [terrainSize, setTerrainSize] = useState<number>(1000);
    const [uploadImage, setUploadImage] = useState<string>("");
    const [imgs, setImgs] = useState(defaultImgs);

    // 关闭警告弹窗
    const closeWarning = () => {
        setShowWarning(false);
    }

    // 打开警告弹窗
    const openWarning = () => {
        setShowWarning(true);
    }

    // 处理加载图片的操作
    const loadImageHandler = () => {
        setShowUpload(true);
    }

    // 处理上传图片继续操作
    const uploadCountinueHandler = async (base64Image: string) => {
        setUploadImage(base64Image);
        const step = drawBoardRef.current?.step();
        if (step) {
            drawBoardRef.current?.addChooseImage();
            openWarning();
            return
        }

        const imgElement = await loadImageFromBase64(base64Image);
        drawBoardRef.current?.boardDrawImage(imgElement);
        drawBoardRef.current?.addChooseImage();
    }

    // 处理生成风格的操作
    // name: 风格名称
    const generateStyleHandler = async (name: string) => {
        // console.log(name, terrainSize);
        console.log("generateStyleHandler", name);
        await generateModel();
    }

    // // 处理地形大小变化
    const terrainSizeHandler = (size: number) => {
        setTerrainSize(size);
    }

    // 处理随机按钮点击事件
    const clickRandomBtn = async () => {
        // 生成4个新的唯一ID
        const ids = Array.from({ length: 4 }, () => uuidv4());

        let response, urls: Array<string> = [];
        for (let i = 0; i < imgs.length; i++) {
            try {
                response = await randomMap();
                urls.push(await loadReader(response?.data));
            } catch (error) {
                break
            }
        }

        // 使用新生成的ID创建新的图片数组
        const newImgs = ids.map((id, index) => ({
            src: urls[index],
            id
        }));

        // 更新图片状态
        setImgs(newImgs);
    }

    const randomClickImageHandler = async (id: string) => {
        const image = imgs.find(img => img.id === id)?.src;
        if (!image) return;

        try {
            const img = await loadImageFromBase64(image);
            drawBoardRef.current?.boardDrawImage(img);
            drawBoardRef.current?.addChooseImage();
        } catch (error) {
            console.error('加载图片失败:', error);
        }
    }

    // 处理文本转图片点击事件
    const textToImageClickHandler = async (base64Image: string) => {
        const imgElement = await loadImageFromBase64(base64Image);
        drawBoardRef.current?.boardDrawImage(imgElement);
        drawBoardRef.current?.addChooseImage();
    }

    const generateModel = async () => {
        // 获取当前画板上村庄的坐标
        const pos = await drawBoardRef.current?.pos();
        // 获取当前画板上的图片
        const blob = await drawBoardRef.current?.toBlob();

        const jsonObject = {
            village: pos,
            map_type: 1, // 1: color map, 2: map gen
            map_size: terrainSize
        };
        const jsonString = JSON.stringify(jsonObject);

        const jsonBlob = new Blob([jsonString], { type: 'application/json' });
        const formData = new FormData();
        // 如果地形大小为500或750，则裁剪图片
        if (terrainSize === 500 || terrainSize === 750) {
            formData.append('files', blob, 'map.png');
            const cropImageBlob = await cropImage(blob, terrainSize);
            formData.append('files', cropImageBlob, 'map_preview.png');
        } else {
            formData.append('files', blob, 'map.png');
        }

        formData.append('files', jsonBlob, 'data.json');

        try {
            var response = await createTask(formData, 1);
            if (response.status === 200) {
                navigate("/home/preview", { state: { param, task_id: response.data.task_id, from: "colormap", size: terrainSize } });
            }
        } catch (error: any) {
            console.log(error.status);
            if (error.status === 403) { }
            return;
        };
    }

    const waringContinue = async () => {
        const imgElement = await loadImageFromBase64(uploadImage);
        drawBoardRef.current?.boardDrawImage(imgElement);
    }

    // 创建一个函数来生成序列号
    const generateSerialNumbers = () => {
        let currentNumber = 1;
        return () => currentNumber++;
    };

    // 在渲染函数内部创建序列号生成器
    const getNextSerialNumber = generateSerialNumbers();

    return (
        <div>
            <div>
                <div className=" flex items-center">
                    <Link to="/home/scene/choice" className="flex items-center cursor-pointer">
                        <img src={backIcon} alt="back" width={14} height={14} />
                        <span className="ml-2 text-link">Back</span>
                    </Link>
                    <h2 className=" ml-10">Create a new scene</h2>
                </div>
            </div>
            <div className="flex mt-4 flex-col sm:flex-row">
                <div className="space-y-4 flex-1">
                    {param === "text" && <TextToImage
                        serialNumber={getNextSerialNumber()}
                        clickImg={textToImageClickHandler}
                    />}

                    {param === "template" && <RandomImage
                        serialNumber={getNextSerialNumber()}
                        isDisable={false}
                        imgList={imgs}
                        clickImg={randomClickImageHandler}
                        clickRandomBtn={clickRandomBtn}
                    />}

                    <TerrainSize
                        serialNumber={getNextSerialNumber()}
                        isDisable={false}
                        callback={terrainSizeHandler}
                        options={[
                            { size: 500, label: "500 * 500m", iconSize: "w-8 h-8" },
                            { size: 750, label: "750 * 750m", iconSize: "w-9 h-9" },
                            { size: 1000, label: "1000 * 1000m", iconSize: "w-9 h-9" },
                        ]}
                        defaultSize={terrainSize}
                    />

                    <GenerateStyle
                        serialNumber={getNextSerialNumber()}
                        clickImgHandler={generateStyleHandler}
                        isShowImageList={false}
                    />
                </div>
                <div className="board sm:ml-6 relative">
                    <DrawingBoard
                        ref={drawBoardRef}
                        imageListIndex={() => { }}
                        disableBrush={false}
                        taskTempStatus={360}
                        coord={[]}
                        switchImg={() => { }}
                        loadImage={loadImageHandler}
                    />
                    {terrainSize < 1000 && <Mask size={terrainSize} />}
                </div>
            </div>
            <UploadMap
                isVisible={showUpload}
                item={[".jpeg, .jpg, .png supported", "Less than 10MB", "Please use an image that includes the following color values: #F7ECC2, #9B9D9B, #48A3F4, and #80D752. "]}
                onCancel={() => setShowUpload(false)}
                onContinue={uploadCountinueHandler}
            />
            <Warning
                isVisible={showWarning}
                text="Uploading an image will replace your current drawing."
                onCancel={closeWarning}
                onContinue={waringContinue}
            />
        </div>
    );
};

export default ColorMap;