function ModelProgress({ loading, value, waiting }: { loading: boolean, value: number, waiting: number }) {

    if (!loading) {
        return (
            <span>Click “Generate 3D preview” to have your preview</span>
        )
    }

    if (loading && !waiting) {
        return (
            <>
                <div className="mx-2">
                    <progress value={value} max="100" className=' w-60 model-progress'></progress>
                </div>
                <span>Generating...Estimated finish time 5 min.</span>
            </>
        )
    }

    if (loading && waiting) {
        return (
            <>
                <div className="mx-2">
                    <progress value={value} max="100" className=' w-60 model-progress'></progress>
                </div>
                <span>Number of people waiting: {waiting}</span>
            </>
        )
    }

};
export default ModelProgress;