interface Color {
    name: string;
    r: number;
    g: number;
    b: number;
}

// 将RGB转换为HSV的函数
export function rgbToHsv(r: number, g: number, b: number): { h: number, s: number, v: number } {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h = 0, s = 0, v = max;

    const d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max !== min) {
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        h /= 6;
    }

    return { h: h * 360, s: s * 100, v: v * 100 };
}

// 计算两个颜色之间的欧几里得距离
function colorDistance(r1: number, g1: number, b1: number, r2: number, g2: number, b2: number): number {
    return Math.sqrt(Math.pow(r1 - r2, 2) + Math.pow(g1 - g2, 2) + Math.pow(b1 - b2, 2));
}

// 定义目标颜色
const targetColors: Color[] = [
    { name: "Blue", r: 20, g: 163, b: 251 },   // 蓝色
    { name: "Yellow", r: 249, g: 236, b: 189 },  // 黄色
    { name: "Green", r: 92, g: 219, b: 52 },    // 绿色
    { name: "Gray", r: 155, g: 157, b: 155 }   // 灰色
];

// 根据HSV值找到最接近的颜色
function findClosestColor(h: number, s: number, v: number): Color {
    let closestColor: Color | null = null;
    let minDistance = Infinity;

    for (const color of targetColors) {
        const hsv = rgbToHsv(color.r, color.g, color.b);
        const distance = colorDistance(h, s, v, hsv.h, s, v);

        if (distance < minDistance) {
            minDistance = distance;
            closestColor = color;
        }
    }

    return closestColor as Color;
}

// 核心处理函数，将图像颜色转换为目标颜色
export function convertImageToTargetColors(image: HTMLImageElement): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    canvas.width = image.width;
    canvas.height = image.height;

    ctx.drawImage(image, 0, 0);

    const imageData = ctx.getImageData(0, 0, image.width, image.height);
    const pixels = imageData.data;

    for (let i = 0; i < pixels.length; i += 4) {
        const r = pixels[i];
        const g = pixels[i + 1];
        const b = pixels[i + 2];

        const { h, s, v } = rgbToHsv(r, g, b);

        const closestColor = findClosestColor(h, s, v);

        pixels[i] = closestColor.r;
        pixels[i + 1] = closestColor.g;
        pixels[i + 2] = closestColor.b;
    }

    ctx.putImageData(imageData, 0, 0);

    return canvas;
}


// 定义允许的颜色范围（黄色、蓝色、灰色、绿色）
const allowedColorRanges = [
    { name: "Yellow", hMin: 40, hMax: 60, sMin: 20, sMax: 100, vMin: 50, vMax: 100 },    // 黄色
    { name: "Blue", hMin: 180, hMax: 240, sMin: 30, sMax: 100, vMin: 50, vMax: 100 },  // 蓝色
    { name: "Green", hMin: 100, hMax: 140, sMin: 30, sMax: 100, vMin: 50, vMax: 100 },   // 绿色
    { name: "Gray", hMin: 0, hMax: 360, sMin: 0, sMax: 20, vMin: 20, vMax: 80 }        // 灰色（低饱和度）
];

// 判断像素是否在允许的颜色范围内
function isAllowedColor(h: number, s: number, v: number) {
    for (const range of allowedColorRanges) {
        if (h >= range.hMin && h <= range.hMax &&
            s >= range.sMin && s <= range.sMax &&
            v >= range.vMin && v <= range.vMax) {
            return true;
        }
    }
    return false;
}


// 检查并转换图像颜色范围
export function checkAndConvertImageColorRange(image: HTMLImageElement): { outOfRangeRatio: number, canvas: HTMLCanvasElement } {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = image.width;
    canvas.height = image.height;

    if (!ctx) {
        throw new Error('Unable to get canvas context');
    }

    ctx.drawImage(image, 0, 0);

    const imageData = ctx.getImageData(0, 0, image.width, image.height);
    const pixels = imageData.data;

    let outOfRangeCount = 0;

    for (let i = 0; i < pixels.length; i += 4) {
        const r = pixels[i];
        const g = pixels[i + 1];
        const b = pixels[i + 2];

        const { h, s, v } = rgbToHsv(r, g, b);

        // 检查颜色是否在允许范围内
        if (!isAllowedColor(h, s, v)) {
            outOfRangeCount++;

            // 如果颜色不在范围内，寻找最接近的目标颜色
            const closestColor = findClosestColor(h, s, v);

            // 将像素颜色替换为最接近的目标颜色
            pixels[i] = closestColor.r;
            pixels[i + 1] = closestColor.g;
            pixels[i + 2] = closestColor.b;
        }
    }

    ctx.putImageData(imageData, 0, 0);

    const outOfRangeRatio = outOfRangeCount / (pixels.length / 4);

    return { outOfRangeRatio, canvas };
}
