import axios from 'axios';
import { getLocalToken, clearLocalToken, setLocalToken } from './utils';
import { message } from 'antd';
import { refreshToken } from './refreshToken';
import { RefreshToken } from '@/types';

console.log("BASE_URL: ", import.meta.env.VITE_APP_BASE_URL);
console.log("DOWNLOAD: ", import.meta.env.VITE_APP_MODE);

const instance = axios.create({
  // baseURL: "/api/"
  baseURL: import.meta.env.VITE_APP_BASE_URL, // api base url
  // headers: {'Content-Type': 'application/json'}
});

// request interceptor
instance.interceptors.request.use(
  config => {
    // add token
    config.headers["x-id-token"] = getLocalToken();
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// response interceptor
instance.interceptors.response.use(
  response => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response) {
      
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const response: RefreshToken = await refreshToken();
          let newToken = response.token;
          localStorage.setItem("photoURL", response.photoUrl);
          setLocalToken(newToken);
          originalRequest.headers["x-id-token"] = newToken;
          return axios(originalRequest);
        } catch (refreshError) {
          clearLocalToken();
          console.error('刷新令牌失败', refreshError);
          window.location.href = '/login';
        }
      }
      
      
      // The request was issued, but the server response status code was not within the 2xx range
      switch (error.response.status) {
        case 400:
          message.error('Request error');
          break;
        case 403:
          message.error('access denied');
          break;
        case 404:
          message.error('Error requesting address');
          break;
        case 413:
          message.error('Request Entity Too Large');
          break;
        case 500:
          message.error('Server internal error');
          break;
        default:
          message.error(`connection error ${error.response.status}`);
      }
    } else {
      console.log("code: ", error.response.status);
      // Some errors are triggered when setting up the request
      message.error(`Error: ${error.message}`);
    }
    return Promise.reject(error);
  }
);

export default instance;
