import { useCallback, useEffect } from 'react';
import cx from "classnames";

import {
  DEFAULT_MAPGEN_SELECT,
  MAPGEN_SIZE_KEYWORDS,
  MAPGEN_TYPE_KEYWORDS,
  MAPGEN_VAILLAGE_KEYWORDS,
} from './constants';
import deleteVaillageIcon from '@/assets/mapgen/deleteVaillage.svg';
import largeIcon from '@/assets/mapgen/large.svg';
import mediumIcon from '@/assets/mapgen/medium.svg';
import mountainIcon from '@/assets/mapgen/mountain.svg';
import oceanIcon from '@/assets/mapgen/ocean.svg';
import resetIcon from '@/assets/mapgen/reset.svg';
import smallIcon from '@/assets/mapgen/small.svg';
import tinyIcon from '@/assets/mapgen/tiny.svg';
import vaillageIcon from '@/assets/mapgen/vaillage.svg';
import valleyIcon from '@/assets/mapgen/valley.svg';
import waterIcon from '@/assets/mapgen/water.svg';
import { MapgenSelect, MapgenTypeEnums } from '@/types';
import { Tooltip } from 'antd';

interface IProps {
  mapgenSelect: Partial<MapgenSelect>;
  setMapgenSelect: React.Dispatch<React.SetStateAction<Partial<MapgenSelect>>>;
}


export default function MapgenTool(props: IProps) {
  const { mapgenSelect, setMapgenSelect } = props;
  const mapgenSelectHandler = (param: Partial<MapgenSelect>) => {
    if (param.vaillage) {
      setMapgenSelect(param);
    } else {
      setMapgenSelect((preVal) => {
        return {
          ...DEFAULT_MAPGEN_SELECT,
          ...preVal,
          ...param,
          vaillage: undefined,
        };
      });
    }
  };

  const mapgenTypeHighlight = useCallback(
    (type: MapgenTypeEnums) => {
      const baseClass = "mr-4 border-2 rounded-full focus-visible:outline-none";
      if (
        mapgenSelect.type !== type ||
        mapgenSelect.vaillage === MapgenTypeEnums.vaillage
      ) {
        return `${baseClass} border-transparent`;
      }
      return baseClass;
    },
    [mapgenSelect],
  );

  const mapgenVaillageHighlight = useCallback(
    (type: MapgenTypeEnums) => {
      const baseClass =
        "mr-4 p-1 border-2 rounded-full focus-visible:outline-none";
      if (mapgenSelect.vaillage !== type) {
        return `${baseClass} border-transparent`;
      }
      return baseClass;
    },
    [mapgenSelect],
  );

  const mapgenSizeHighlight = useCallback(
    (type: MapgenTypeEnums) => {
      const baseClass =
        "mr-4 p-0.5 border-2 rounded-full focus-visible:outline-none";
      if (
        mapgenSelect.size !== type ||
        mapgenSelect.vaillage === MapgenTypeEnums.vaillage
      ) {
        return `${baseClass} border-transparent`;
      }
      return baseClass;
    },
    [mapgenSelect],
  );

  const popupKeyUp = (e: KeyboardEvent) => {
    if (Object.keys(MAPGEN_TYPE_KEYWORDS).includes(e.key)) {
      mapgenSelectHandler({ type: MAPGEN_TYPE_KEYWORDS[e.key] });
    } else if (Object.keys(MAPGEN_VAILLAGE_KEYWORDS).includes(e.key)) {
      mapgenSelectHandler({ vaillage: MAPGEN_VAILLAGE_KEYWORDS[e.key] });
    } else if (Object.keys(MAPGEN_SIZE_KEYWORDS).includes(e.key)) {
      mapgenSelectHandler({ vaillage: MAPGEN_SIZE_KEYWORDS[e.key] });
    } else if (e.key === 'y') {
      clearVillageHandler();
    }
  };

  const clearVillageHandler = () => {
    setMapgenSelect({
      type: MapgenTypeEnums.ocean,
      vaillage: undefined,
      size: MapgenTypeEnums.medium,
    })
  }

  useEffect(() => {
    //监听键盘事件
    document.addEventListener("keyup", popupKeyUp, false);
    return () => {
      //销毁键盘事件
      document.removeEventListener("keyup", popupKeyUp, false);
    };
  });

  const displayClearVillage = () => {
    return ![MapgenTypeEnums.vaillage, MapgenTypeEnums.deleteVaillage].includes(mapgenSelect.vaillage as MapgenTypeEnums)
  }

  const mapgenTypeRender = [
    {
      id: "ocean",
      title: "Ocean",
      icon: <img src={oceanIcon} />,
      type: MapgenTypeEnums.ocean,
    },
    {
      id: "shallow",
      title: "Water",
      icon: <img src={waterIcon} />,
      type: MapgenTypeEnums.water,
    },
    {
      id: "valley",
      title: "Valley",
      icon: <img src={valleyIcon} />,
      type: MapgenTypeEnums.valley,
    },
    {
      id: "mountain",
      title: "Mountain",
      icon: <img src={mountainIcon} />,
      type: MapgenTypeEnums.mountain,
    },
  ];

  const mapgenVaillageRender = [
    {
      id: "vaillage",
      title: <>
        <div>Add town</div>
        <ul className='text-xs list-disc text-white/50'>
          <li className='relative left-4 mt-1'>Max 3 settlements</li>
          <li className='relative left-4'>Overlap can lead to incorrect generation</li>
        </ul>
      </>,
      overlayClassName: '[&_.ant-tooltip-inner]:w-[260px]',
      icon: <img src={vaillageIcon} />,
      type: MapgenTypeEnums.vaillage,
    },
    {
      id: "deleteVaillage",
      title: "Delete town",
      overlayClassName: '',
      icon: <img src={deleteVaillageIcon} />,
      type: MapgenTypeEnums.deleteVaillage,
    },
  ];

  const mapgenSizeRender = [
    {
      id: "xtiny",
      title: "Brush size:Tiny",
      icon: <img src={tinyIcon} />,
      type: MapgenTypeEnums.xtiny,
    },
    {
      id: "tiny",
      title: "Brush size:Small",
      icon: <img src={smallIcon} />,
      type: MapgenTypeEnums.tiny,
    },
    {
      id: "small",
      title: "Brush size:Medium",
      icon: <img src={mediumIcon} />,
      type: MapgenTypeEnums.small,
    },
    {
      id: "medium",
      title: "Brush size:Large",
      icon: <img src={largeIcon} />,
      type: MapgenTypeEnums.medium,
    },
  ];

  return (
    <div className="flex flex-col" id="action">
      <div className="mt-4 flex items-center">
        {mapgenTypeRender.map((item) => (
          <Tooltip
            title={item.title}
            key={item.type}>
            <button
              id={item.id}
              className={mapgenTypeHighlight(item.type)}
              onClick={() => {
                mapgenSelectHandler({ type: item.type });
              }}
            >
              {item.icon}
            </button>
          </Tooltip>
        ))}
        {mapgenVaillageRender.map((item) => (
          <Tooltip
            title={item.title}
            key={item.type}
            overlayClassName={item.overlayClassName}
          >
            <button
              id={item.id}
              className={mapgenVaillageHighlight(item.type)}
              onClick={() => {
                mapgenSelectHandler({ vaillage: item.type });
              }}
            >
              {item.icon}
            </button>
          </Tooltip>
        ))}
        <Tooltip title='Reset town'>
          <button
            id="clearVillage"
            className={cx('mr-4 flex flex-col items-center justify-between px-1', {
              'cursor-not-allowed': displayClearVillage(),
            })}
            disabled={displayClearVillage()}
            onClick={() => clearVillageHandler()}
          >
            <img src={resetIcon} />
          </button>
        </Tooltip>
        {mapgenSizeRender.map((item) => (
          <Tooltip
            title={item.title}
            key={item.type}>
            <button
                key={item.type}
                id={item.id}
                title={item.title}
                className={mapgenSizeHighlight(item.type)}
                onClick={() => {
                  mapgenSelectHandler({ size: item.type });
                }}
              >
              {item.icon}
            </button>
          </Tooltip>
        ))}
        <button id="large">
            <svg viewBox="-50 -50 100 100" color="#ffffff">
              <circle r="50" />
            </svg>
          </button>
      </div>
      <div className="pen flex"></div>
    </div>
  );
}