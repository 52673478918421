import ReactDOM from 'react-dom/client'
import store from "./store/store";
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.tsx'
import "../tailwind.css";
import "@fontsource/urbanist/400.css";
import './index.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
)
