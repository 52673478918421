import { useState, useRef, useEffect, useId } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from 'axios';
import { message, notification, Modal } from 'antd';
import { useUpdateEffect, useFullscreen } from "ahooks";
import ThreeScene from "@/components/ThreeScene/ThreeScene";
import UEScene from "@/components/UEScene/UEScene";
import { taskStatus, confirmTask, closeTask, taskRender, getModelURL, getTaskList, getVillageFile } from "@/service/taskService";
import { disabledButton, enabledButton, getLocalToken, updateState, downloadCheck, checkUserWaitlist } from "@/lib/utils";
import { reconnectUESceneNotice, ueNotification } from "@/lib/notification";
import { GenerateState, VillagePos } from "@/types";
import fullScreenIcon from "@/assets/fullscreen.svg";
import fullScreenExitIcon from "@/assets/fullscreen_exit.svg";
import downloadIcon from "@/assets/download.svg";
// import previewLeftArrowIcon from "@/assets/preview-back.png";
import villageIcon from "@/assets/village1.svg";
import backIcon from "@/assets/back.png";
import "@/App.css";
import "./Preview.css";
import UEProgress from "@/components/UEProgress";
import ModelProgress from "@/components/ModelProgress";

const ASSET_MODEL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_MODEL;
const MODEL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_DOWNLOAD_MODEL;

type Wait = {
    status: number,
    text: string
}

function Preview() {
    const location = useLocation();
    const { task_id, from, size, biome, town } = location.state || {};
    const navigate = useNavigate();
    const [genPersceneLoading, setGenPersceneLoading] = useState<boolean>(false);
    const [generateLoading, setGenerateLoading] = useState<boolean>(false);
    const [perfectionScene, setPerfectionScene] = useState<boolean>(false);
    const [modelUrl, setModelUrl] = useState<string>("");
    const queryType = useRef<string>("model");
    const timerRef = useRef<string | number | undefined | NodeJS.Timeout>(undefined);
    const isModelDone = useRef<boolean>(false);
    const [mapURL, setMapURL] = useState<string>("");
    const taskid = useRef<string>("");
    const [taskID, setTaskID] = useState<string>("");
    const taskTempStatus = useRef<number>(0);
    const generateState = useRef<GenerateState>({ text: "null", time: 0, total_time: 0, value: 0, currentState: 0, taskAhead: 0, renderAhead: 0 });
    const modelFullRef = useRef(null);
    const [_, setUpdateGenerateState] = useState<boolean>(false);   // 用于更新generateState
    const [isUeFullScreen, setIsUeFullScreen] = useState<boolean>(false);
    const [triggerPollingTask, setTriggerPollingTask] = useState<boolean>(false);
    const [triggerGetModelURL, setTriggerGetModelURL] = useState<boolean>(false);
    const [triggerConnectWS, setTriggerConnectWS] = useState<boolean>(false);
    const fullScreenBtnRef = useRef<HTMLButtonElement>(null);
    const [isModelFullscreen, { enterFullscreen: enterModelFullscreen, exitFullscreen: exitModelFullscreen }] = useFullscreen(modelFullRef);
    const notifiKey = useId();
    const [ueClosed, setUeClosed] = useState<boolean>(false);
    const [historyCoord, setHistoryCoord] = useState<Array<VillagePos>>([]);
    const reconnectionCount = useRef<number>(0);
    const [waitlist, setWaitlist] = useState<Wait>({ text: "", status: 0 });
    const networkceathCount = useRef<number>(0);

    useEffect(() => {
        if (task_id) {
            historyTaskView();
            const mapURLName = from === "mapgen" || size === 1000 ? "/map.png" : "/map_preview.png";
            const url = `${import.meta.env.VITE_APP_BASE_URL}${import.meta.env.VITE_APP_ASSET_FILE}${task_id}${mapURLName}`;
            setMapURL(url);
        }

        const script = document.createElement('script');
        script.src = '../js/mp4box.all.min.js';
        script.async = true;
        document.body.appendChild(script);

        // 阻止使用空格键控制全屏
        const blockFullScreen = (event: KeyboardEvent) => {
            if (event.code === 'Space' && document.activeElement === fullScreenBtnRef.current) event.preventDefault();
        };
        // 阻止使用空格键控制全屏
        document.addEventListener('keydown', blockFullScreen);
        return () => {
            enabledButton();
            document.removeEventListener('keydown', blockFullScreen);
            document.body.removeChild(script);
            notification.destroy(notifiKey);
            timerRef.current && clearInterval(timerRef.current);
        };
    }, []);

    useEffect(() => {
        async function waitlist() {
            let data;
            let content = "";
            try {
                data = await checkUserWaitlist();
                if (data.name) {
                    setWaitlist({ status: 0, text: "You are now on our waiting list, thanks" });
                };
                await getTaskList();
            } catch (e: any) {
                if (data.name) {
                    content = "You are now on our waiting list, thanks";
                }
                setWaitlist({ text: content, status: e.status })
            }
        }
        waitlist();
    }, []);


    // 处理查看历史记录预览
    const historyTaskView = async () => {
        var response;
        try {
            response = await taskStatus(task_id);
            var pos = await getVillageFile(task_id);
            setHistoryCoord(pos.data.village);
        } catch (error) {
            message.error("Error in querying task status!");
        }
        var status = response?.data.status;
        // console.log("status:", status);

        if (status === undefined) return
        status = parseInt(status);

        if (status >= 210) {
            // 加载历史记录中的模型
            var modelURL = ASSET_MODEL + task_id + "/scene.fbx";
            setModelUrl(modelURL);
        }
        // 从历史记录进来以后同步当前页面中taskid和generate-state
        taskid.current = task_id!;
        generateState.current.currentState = status;
        taskTempStatus.current = status;

        // 在画板上绘制历史记录中的图片
        let mapURL = import.meta.env.VITE_APP_BASE_URL + import.meta.env.VITE_APP_ASSET_FILE + task_id + "/map.png";
        console.log(mapURL);

        console.log(status);

        if (status === 100 || status === 200) {
            setGenerateLoading(true);
            disabledButton();
            setTriggerPollingTask(!triggerPollingTask);
        }

        if (status === 360) {
            setTriggerConnectWS(!triggerConnectWS);
        }

        if (status === 220 || status === 230 || status === 260 || status === 300) {
            disabledButton();
            queryType.current = "ue";
            setGenPersceneLoading(true);
            setTriggerPollingTask(!triggerPollingTask);
        }
    }

    // 从历史记录中快速生成UE场景，当status是900时
    const historyGenerateScene = async () => {
        if (generateState.current.time || generateState.current.value) {
            generateState.current.time = 0;
            generateState.current.value = 0;
        }
        disabledButton();
        try {
            await taskRender(taskid.current);
            setGenPersceneLoading(true);
            setTriggerPollingTask(!triggerPollingTask);
        } catch (error) {
            console.error(error);
            message.error("Error in generating UE scene!");
        }
        //enabledButton();
    }

    const idleTip = () => {
        // idletip提示过三次，则提示网络慢
        networkceathCount.current += 1;
        if (networkceathCount.current >= 2) {
            Modal.confirm({
                title: "Tips",
                content: `The connection has been disconnected due to prolonged inactivity or a slow network speed. If your network speed is slow, 
                please try switching to a different network and reconnecting.`,
                cancelButtonProps: { style: { display: 'none' } },
                onOk: () => {
                    networkceathCount.current = 0;
                }
            });
            return;
        }
        Modal.confirm({
            title: "Tips",
            content: `You have been inactive for a while, are you still there? 
            The 3D environment will automatically close to free up resources.`,
            okText: "I am here",
            cancelText: "Close task",
            onOk: reconnect,
            onCancel: closeWS
        });
    }

    const reconnect = async () => {
        setPerfectionScene(false);
        setTaskID("");
        if (reconnectionCount.current >= 2) {
            reconnectionCount.current = 0;
            idleTip();
            return
        };
        reconnectionCount.current += 1;
        setTriggerConnectWS(!triggerConnectWS);
    }

    const tryToReconnect = () => {
        return <button onClick={() => {
            setTriggerConnectWS(!triggerConnectWS);
        }}>Try to reconnect</button>
    };

    // 触发轮询任务
    useUpdateEffect(() => {
        timerRef.current && clearInterval(timerRef.current);
        timerRef.current = setInterval(() => {
            queryStatusTask(taskid.current);
        }, 2000);
    }, [triggerPollingTask]);

    // 触发获取模型
    useUpdateEffect(() => {
        getModelUrl(taskid.current);
    }, [triggerGetModelURL]);

    // 触发链接websocket
    useUpdateEffect(() => {
        // notification.destroy(notifiKey);
        async function connect() {
            var response;
            try {
                response = await taskStatus(taskid.current);
            } catch (error) {
                // @ts-ignore
                console.log("error:", error.message);
                reconnectUESceneNotice(tryToReconnect, notifiKey);
            }

            if (response?.data.status === "360") {
                setTaskID(taskid.current);
                setPerfectionScene(true);
                notification.destroy(notifiKey);
            }

            if (response?.data.status === "900") {
                generateState.current.currentState = 900;
                setUpdateGenerateState(prev => !prev);
                historyGenerateScene();
                notification.destroy(notifiKey);
            }

        }
        connect();
    }, [triggerConnectWS]);

    //  获取模型URL
    const getModelUrl = async (task_id: string) => {
        var url = ASSET_MODEL + task_id + "/scene.fbx";
        console.log("url", url);
        try {
            var response = await getModelURL(task_id);;
            setGenerateLoading(false);
            clearInterval(timerRef.current);
            response.status === 200 && setModelUrl(url);
            isModelDone.current = false; // 当轮询状态为210时表示模型生成完毕
        } catch (error) {
            clearInterval(timerRef.current);
            message.error("An error occurred at the server level");
        }
        setGenerateLoading(false);
    }

    // 获取轮询状态
    const queryStatusTask = async (task_id: string) => {
        var response: AxiosResponse;
        var state = generateState.current;
        try {
            response = await taskStatus(task_id);
            var status = parseInt(response.data.status);
            if (status === 100) {
                state.taskAhead = response.data.task_queue_ahead;
            };

            if (status === 210) {
                // 当轮询状态为210时表示模型生成完,此时触发模型下载
                setTriggerGetModelURL(!triggerGetModelURL);
                clearInterval(timerRef.current);
                enabledButton();
            };

            if (status === 220 || status === 260) {
                state.taskAhead = response.data.task_queue_ahead;
                state.renderAhead = response.data.render_queue_ahead;
            }

            if (status >= 240 && status < 250) {
                generateState.current.value = 0;
                generateState.current.time = 0;
                setGenPersceneLoading(false);
                setGenerateLoading(false);
                clearInterval(timerRef.current);
                enabledButton();
                Modal.error({
                    title: "Tips",
                    content: "An error occurred while generating the required files for the 3D model/scene.",
                    cancelButtonProps: { style: { display: 'none' } },
                });
            };

            if (status === 360) {
                clearInterval(timerRef.current);
                setTriggerConnectWS(!triggerConnectWS); // 触发链接websocket
                setGenPersceneLoading(false);
                enabledButton();
                // const audio = document.getElementById('audio') as HTMLAudioElement;
                // audio?.play();
            };

            if (status >= 370 && status < 380) {
                clearInterval(timerRef.current);
                enabledButton();
                setGenPersceneLoading(false);
                message.error({
                    content: "An error occurred while generating the UE scene.",
                    duration: 10
                });
            };
            updateState(state, taskTempStatus, queryType.current, status);
            setUpdateGenerateState(prev => !prev);
        } catch (error) {
            setGenerateLoading(false);
            clearInterval(timerRef.current);
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
            enabledButton();
        };
    }

    // 展示生成UE场景
    const showPerfectionScene = async (task_id: string) => {
        let isNotice = ueNotification(taskTempStatus.current);
        if (isNotice) return
        queryType.current = "ue";
        setGenPersceneLoading(true);
        disabledButton();
        generateState.current.value = 0;
        try {
            var response = await confirmTask(task_id);
            if (response.status === 200) {
                setTriggerPollingTask(!triggerPollingTask);
            }
        } catch (error) {
            clearInterval(timerRef.current);
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
        }
    }

    // 关闭UE连接
    const closeWS = async () => {
        setUeClosed(true);
        try {
            if (generateState.current.currentState >= 360) {
                var response = await closeTask(taskid.current);
                console.log("关闭状态：", response.status);
                generateState.current.currentState = 900;
                //setUpdateGenerateState(prev => !prev);
            }
            setTimeout(() => {
                setUeClosed(false);
                setPerfectionScene(false);
            }, 5000);

            setTaskID("");
        } catch (error) {
            message.error(`An error occurred:, ${(error as AxiosError)?.message}`);
        }
    }

    const buttonStyle = { backgroundColor: generateLoading || genPersceneLoading ? "#ccc" : "" };

    return (
        <main className="h-full pt-0 flex-1 flex flex-col">
            <section className="pb-4">
                <div className=" flex items-center">
                    <p className="flex items-center cursor-pointer mr-4" onClick={() => {
                        navigate(-1);
                    }}>
                        <img src={backIcon} alt="back" width={14} height={14} />
                        <span className="ml-2 text-link">Back</span>
                    </p>
                    <h2>Create a new scene</h2>
                </div>
            </section>
            <div className="flex-1 flex">
                <div className="flex flex-col">
                    <div className="px-6 py-6 pt-3 rounded-md bg-gradient w-[550px] flex-1">
                        <div className=" text-white font-light flex items-center justify-between pb-2">
                            {/* <p className="flex items-center preview-back-btn" onClick={() => {
                                //navigate(-1)
                            }}>
                                <img src={previewLeftArrowIcon} alt="" width={14} height={14} />
                                <span className="ml-2">Previous Step</span>
                            </p> */}
                        </div>

                        <div className="flex justify-between pb-2 relative">
                            {mapURL && <img src={mapURL} alt="" width={502} height={502} />}
                            {
                                historyCoord.length > 0 && historyCoord.map((item, index) => {
                                    return <img key={index} src={villageIcon} alt="" width={24} height={24} style={{ position: "absolute", top: `${item.y * 512}px`, left: `${item.x * 512}px` }} />
                                })
                            }
                        </div>
                        <div>
                            {
                                <ul className="text-white opacity-50 mt-2 text-sm">
                                    <li>Size: {size}</li>
                                    {from === "mapgen" ? (
                                        <>
                                            <li>Biome: {biome}</li>
                                            <li>Town: {town}</li>
                                        </>
                                    ) : null}
                                    <li>Task: {task_id}</li>
                                </ul>
                            }
                        </div>
                        {waitlist.status === 403 && <p className=" text-red-500">{waitlist.text}</p>}
                    </div>
                </div>

                <div className="flex-1 pl-4 flex flex-col">
                    <div className={`flex-1 w-full flex flex-col justify-center m-auto bg-[#191919] rounded-md text-[#5E5E5E] mb-4 px-4 pt-0 pb-4`} ref={modelFullRef}>
                        <div className="flex justify-between mt-2">
                            <p className=" flex items-center text-white py-2 text-base font-light">
                                <span>3D Preview</span>
                            </p>
                            <div className="flex items-center">
                                <button onClick={() => { isModelFullscreen ? exitModelFullscreen() : enterModelFullscreen() }} className="outline-none">
                                    <img src={isModelFullscreen ? fullScreenExitIcon : fullScreenIcon} alt="" className="h-5" />
                                </button>
                                <button
                                    className="mx-4"
                                    onClick={() => {
                                        if (!modelUrl) {
                                            message.warning("No files to download.");
                                            return
                                        }
                                        var url = MODEL + taskid.current + `?token=${getLocalToken()}`;
                                        window.open(url, '_blank');
                                    }}>
                                    <img src={downloadIcon} alt="" />
                                </button>
                                <button
                                    className={`gradient-border rounded-md text-base`}
                                    style={{ "background": !genPersceneLoading ? "linear-gradient(to right, #5FB3CE, #132976)" : "linear-gradient(to right, rgba(95, 179, 206, 0.5), rgba(19, 41, 118, 0.5))" }}
                                    onClick={() => showPerfectionScene(taskid.current)}
                                >{!genPersceneLoading ? "Generate 3D scene" : "Generating"}</button>
                            </div>
                        </div>
                        {
                            modelUrl ?
                                <ThreeScene
                                    isFull={isModelFullscreen}
                                    modelUrl={modelUrl}
                                    cameraAngle={75}
                                    cameraPosition={size <= 1000 ? from === "mapgen" ? 200 : 800 : 800 }
                                />
                                : <div className="flex justify-center items-center flex-col text-sm h-full select-none">
                                    <ModelProgress
                                        loading={generateLoading}
                                        value={generateState.current.value}
                                        waiting={generateState.current.taskAhead}
                                    />
                                </div>
                        }
                    </div>
                    <div className=" flex-1 w-full rounded-md bg-[#191919] text-[#5E5E5E] overflow-hidden">
                        <div className={`text-white text-sm h-full ${isUeFullScreen ? " fixed top-0 left-0 w-full bg-[#191919]" : ""}`}>
                            <div className="flex justify-between pl-4 pt-3 pr-4 pb-3">
                                <div className="flex items-center">
                                    <span className="text-base">3D Scene</span>
                                </div>
                                <div className="flex items-center">
                                    <button onClick={closeWS}>
                                        Close
                                    </button>
                                    <button
                                        onClick={async () => {
                                            let check = await downloadCheck(taskid.current);
                                            if (check) {
                                                let url = `https://auto3d.blob.core.windows.net/automation-3d/cybever-3d/download.html?env=${import.meta.env.VITE_APP_MODE}&taskid=${taskid.current}&token=${getLocalToken()}&r=${Math.random()}`
                                                window.open(url, "_blank");
                                            }
                                        }}
                                        className="ml-4"
                                    >
                                        <img src={downloadIcon} alt="" />
                                    </button>
                                    <button ref={fullScreenBtnRef} onClick={() => setIsUeFullScreen(!isUeFullScreen)} className="outline-none">
                                        <img src={isUeFullScreen ? fullScreenExitIcon : fullScreenIcon} alt="" className=" h-5 ml-4" />
                                    </button>
                                </div>
                            </div>
                            {perfectionScene && taskID ? <UEScene taskId={taskID} callback={reconnect} /> : ""}
                            <div className="flex justify-center items-center flex-col text-sm h-full select-none text-[#5E5E5E]">
                                <UEProgress
                                    status={generateState.current.currentState}
                                    value={generateState.current.value}
                                    total_time={generateState.current.total_time}
                                    btnStyle={buttonStyle}
                                    loading={genPersceneLoading}
                                    genScene={historyGenerateScene}
                                    taskAhead={generateState.current.taskAhead}
                                    renderAhead={generateState.current.renderAhead}
                                    isClosed={ueClosed}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <audio id="audio" className="hidden" src="/y1561.wav" preload="auto"></audio> */}
        </main>
    );
}

export default Preview;
