import { useRef, useEffect } from "react";
import UEPlayer from "@/lib/ueplayer";
import { getAssetServer, getWebSocketServer } from "@/lib/utils";
import { UESceneProps } from "@/types";
import WeatherControl from "../WeatherControl/WeatherControl";

const UEScene = ({ taskId, callback }: UESceneProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const mapRef = useRef<HTMLDivElement>(null);
    const ueplayerRef = useRef<UEPlayer | null>(null);
    const moveRef = useRef<HTMLDivElement>(null);
    const weatherRef = useRef<HTMLDivElement>(null);
    

    useEffect(() => {
        console.log("Welcome");
        let mapUrl:string; 
        const task_id = BigInt(taskId);
        const release_cutoff_time = BigInt('2024092700000000000');
        if(task_id >= release_cutoff_time){
            mapUrl = getAssetServer() + taskId + "/mini_map.png";
        }else{
            mapUrl = getAssetServer() + taskId + "/map.png";
        }

        ueplayerRef.current = new UEPlayer({
            video: videoRef.current,
            videoUrl: getWebSocketServer() + taskId,
            map: mapRef.current,
            mapUrl: mapUrl,
            move: moveRef.current,
            weather: weatherRef.current,
            onLoss: () => {
                console.log("Stream LOST");
                callback();
            }
        });
        return () => {
            console.log("Bye");
            ueplayerRef.current?.destroy();
            ueplayerRef.current = null;
        };
    }, [taskId]);

    return (
        <>
            <div className={`flex-1 flex justify-center items-center image-stream-container`}>
                <div className="h-full relative w-full action-container">
                    <video muted autoPlay ref={videoRef} id="video" className=" w-full h-full absolute top-0 left-0 object-cover"></video>
                    <div ref={moveRef}>
                        <div className="move">
                            <button className=" absolute bottom-[4rem] left-[4.5rem] keyboard-bg px-4 py-3 rounded-md">W</button>
                            <button className=" absolute bottom-4 left-4 keyboard-bg px-5 py-3 rounded-md">A</button>
                            <button className=" absolute bottom-4 left-[4.5rem] keyboard-bg px-5 py-3 rounded-md">S</button>
                            <button className=" absolute bottom-4 left-32 keyboard-bg px-5 py-3 rounded-md">D</button>
                            <button className=" absolute bottom-4 left-[11.7rem] keyboard-bg px-5 py-3 rounded-md">V</button>
                            <button className=" absolute bottom-4 left-[15.5rem] keyboard-bg px-10 py-3 rounded-md">Space</button>
                        </div>
                        <div className="angle">
                            <button className=" absolute bottom-[4rem] left-[28rem] keyboard-bg px-5 py-3 rounded-md">&uarr;</button>
                            <button className=" absolute bottom-4 left-96 keyboard-bg px-5 py-3 rounded-md">&larr;</button>
                            <button className=" absolute bottom-4 left-[28rem] keyboard-bg px-5 py-3 rounded-md">&darr;</button>
                            <button className=" absolute bottom-4 left-[31.5rem] keyboard-bg px-5 py-3 rounded-md">&rarr;</button>
                        </div>
                    </div>

                    <WeatherControl ref={weatherRef}/>
                    
                    <div className="mini-map absolute bottom-4 right-4" ref={mapRef}></div>
                </div>
            </div>
        </>
    );
};

export default UEScene;