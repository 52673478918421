import { MapgenTypeEnums } from "@/types";

export const MAPGEN_TYPE_KEYWORDS: Record<string, MapgenTypeEnums> = {
  q: MapgenTypeEnums.ocean,
  w: MapgenTypeEnums.water,
  e: MapgenTypeEnums.valley,
  r: MapgenTypeEnums.mountain,
};

export const MAPGEN_VAILLAGE_KEYWORDS: Record<string, MapgenTypeEnums> = {
  t: MapgenTypeEnums.deleteVaillage,
  p: MapgenTypeEnums.vaillage,
};

export const MAPGEN_SIZE_KEYWORDS: Record<string, MapgenTypeEnums> = {
  1: MapgenTypeEnums.xtiny,
  2: MapgenTypeEnums.tiny,
  3: MapgenTypeEnums.small,
  4: MapgenTypeEnums.medium,
  // 5: MapgenTypeEnums.large,
};

export const DEFAULT_MAPGEN_SELECT = {
  type: MapgenTypeEnums.mountain,
  size: MapgenTypeEnums.medium,
};