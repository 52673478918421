import { useState, useEffect, ChangeEvent, MouseEvent, forwardRef, useImperativeHandle, useRef } from "react";
import { DrawingBoardProps, DrawInfo, VillagePos } from "@/types";
import "./DrawingBoard.css";
import { message, Tooltip } from "antd";
import villageIcon from "@/assets/village1.svg";
import { getLocalEmail } from "@/lib/utils";

const DrawingBoard = forwardRef(function (props: DrawingBoardProps, ref) {
    const [canvas, setCanvas] = useState<HTMLCanvasElement | null>(null);
    const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
    const [undoActive, setUndoActive] = useState<boolean>(false);
    const [redoActive, setRedoActive] = useState<boolean>(false);
    const [activeColorIndex, setActiveColorIndex] = useState<number>(0);
    const [rangValue, setRangValue] = useState<number>(20);
    const [tool, setTool] = useState<string>("pen");
    const [villagePos, setVillagePos] = useState<Array<VillagePos>>([]);
    const email = useRef<string | null>("");
    const drawInfo = useRef<DrawInfo>({
        radius: 5,
        lWidth: 20,
        strokeColor: '#9B9D9B',
        step: 0,
        canvasHistory: [], // 实现撤销和重做的功能
        eraserEnabled: false,
        lastPoint: { x: 0, y: 0 }, // 记录画笔最后一次的位置
        painting: false,// 定义一个变量初始化画笔状态
    });
    // const [fileInputKey, setFileInputKey] = useState(Date.now());

    // 向外界暴露的方法
    useImperativeHandle(ref, () => {
        return {
            boardDrawImage: function (img: HTMLImageElement) {
                if (context && canvas) {
                    context.drawImage(img, 0, 0, 512, 512);
                }
            },
            toBlob: function () {
                return new Promise((reslove, _) => {
                    canvas?.toBlob((blob: Blob | null) => {
                        reslove(blob);
                    }, 'image/png');
                });
            },
            canvasClear: function () {
                if (context && canvas) {
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    drawInfo.current.canvasHistory = [];
                    setUndoActive(false);
                    setRedoActive(false);
                    // isRevision.current = false;
                }
            },
            addChooseImage: function () {
                if (canvas) {
                    drawInfo.current.canvasHistory.push(canvas.toDataURL());
                    drawInfo.current.step = 0;
                }
            },
            pos: function () {
                return villagePos
            },
            clearVillage: function () {
                setVillagePos([]);
            },
            step: function () {
                return drawInfo.current.step;
            },
            isColorAllowed: function () {
                const allowedColors = [
                    { r: 249, g: 236, b: 189, a: 255 },
                    { r: 20, g: 163, b: 251, a: 255 },
                    { r: 92, g: 219, b: 52, a: 255 },
                    { r: 155, g: 157, b: 155, a: 255 }
                ];

                const tolerance = 80; // 颜色容差
                let validColorCount = 0;

                if (context && canvas) {
                    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                    const data = imageData.data;
                    for (let i = 0; i < data.length; i += 4) {
                        const r = data[i];
                        const g = data[i + 1];
                        const b = data[i + 2];
                        const a = data[i + 3];
                        // 检查颜色和透明度是否在容差范围内
                        if (allowedColors.some(color =>
                            Math.abs(color.r - r) <= tolerance &&
                            Math.abs(color.g - g) <= tolerance &&
                            Math.abs(color.b - b) <= tolerance &&
                            color.a === a // 确保透明度完全匹配
                        )) {
                            validColorCount++;
                        }
                    }
                    return { valicount: validColorCount, imglen: data.length / 4 };
                }

            }
        }
    });

    useEffect(() => {
        let canvas = document.getElementById('canvas') as HTMLCanvasElement;
        let context = canvas.getContext('2d', { willReadFrequently: true });
        setCanvas(canvas);
        setContext(context!);
        autoSetSize(canvas, context);
        email.current = getLocalEmail();
    }, []);

    useEffect(() => {
        if (props.coord.length) {
            setVillagePos(props.coord);
        }
    }, [props.coord]);

    const autoSetSize = (canvas: HTMLCanvasElement, context: CanvasRenderingContext2D | null) => {
        canvasSetSize();
        function canvasSetSize() {
            if (context && canvas) {
                // 把变化之前的画布内容copy一份，然后重新画到画布上
                // let imgData = context.getImageData(0, 0, canvas.width, canvas.height);
                canvas.width = 512;
                canvas.height = 512;

                // context.fillStyle = '#14a3fb'; blue
                context.fillStyle = "#f9ecbd";  // yellow
                context.fillRect(0, 0, 512, 512);

                // 下半部分黄色
                // context.fillStyle = '#f9ecbd';
                // context.fillRect(0, 256, 512, 256);
                context.strokeStyle = drawInfo.current.strokeColor;
                if (context && canvas) {
                    drawInfo.current.canvasHistory.push(canvas.toDataURL());
                }
                // context.putImageData(imgData, 0, 0);
            }
        }

        // window.onresize = function () {
        //     canvasSetSize();
        // }
    }

    const rangeChange = (event: ChangeEvent<HTMLInputElement>) => {
        let val = parseInt(event.target.value);
        setRangValue(val);
        drawInfo.current.lWidth = val;
    };

    const onmousedownHandle = (e: MouseEvent) => {
        drawInfo.current.painting = true;
        // props.taskTempStatus.current = 0;
        let x1 = e.clientX;
        let y1 = e.clientY;

        if (drawInfo.current.eraserEnabled) {//要使用eraser
            //鼠标第一次点下的时候擦除一个圆形区域，同时记录第一个坐标点
            if (context && canvas) {
                context.save();
                context.globalCompositeOperation = "destination-out";
                context.beginPath();
                drawInfo.current.radius = (drawInfo.current.lWidth / 2) > 5 ? (drawInfo.current.lWidth / 2) : 5;
                context.arc(x1, y1, drawInfo.current.radius, 0, 2 * Math.PI);
                context.clip();
                context.clearRect(0, 0, canvas.width, canvas.height);
                context.restore();
                drawInfo.current.lastPoint = { 'x': x1, 'y': y1 }
            }
        } else {
            drawInfo.current.lastPoint = { 'x': x1, 'y': y1 }
        }
    }

    const onmousemoveHandle = function (e: MouseEvent) {
        let x1 = drawInfo.current.lastPoint['x'];
        let y1 = drawInfo.current.lastPoint['y'];
        let x2 = e.clientX;
        let y2 = e.clientY;
        if (!drawInfo.current.painting) { return }
        if (drawInfo.current.eraserEnabled) {
            moveHandler(x1, y1, x2, y2);
            //记录最后坐标
            drawInfo.current.lastPoint['x'] = x2;
            drawInfo.current.lastPoint['y'] = y2;
        } else {
            if (canvas) {
                let newPoint = { 'x': x2, 'y': y2 };
                const rect = canvas.getBoundingClientRect();
                // console.log('Canvas 元素的左上角相对于视口的坐标：', rect.top, rect.left);
                drawLine(drawInfo.current.lastPoint.x - rect.left, drawInfo.current.lastPoint.y - rect.top, newPoint.x - rect.left, newPoint.y - rect.top);
                drawInfo.current.lastPoint = newPoint;
            }
        }
    }

    const moveHandler = function (x1: number, y1: number, x2: number, y2: number) {
        //获取两个点之间的剪辑区域四个端点
        var asin = drawInfo.current.radius * Math.sin(Math.atan((y2 - y1) / (x2 - x1)));
        var acos = drawInfo.current.radius * Math.cos(Math.atan((y2 - y1) / (x2 - x1)))
        var x3 = x1 + asin;
        var y3 = y1 - acos;
        var x4 = x1 - asin;
        var y4 = y1 + acos;
        var x5 = x2 + asin;
        var y5 = y2 - acos;
        var x6 = x2 - asin;
        var y6 = y2 + acos;

        //保证线条的连贯，所以在矩形一端画圆
        if (context && canvas) {
            context.save()
            context.beginPath()
            context.globalCompositeOperation = "destination-out";
            drawInfo.current.radius = (drawInfo.current.lWidth / 2) > 5 ? (drawInfo.current.lWidth / 2) : 5;
            context.arc(x2, y2, drawInfo.current.radius, 0, 2 * Math.PI);
            context.clip()
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.restore();

            //清除矩形剪辑区域里的像素
            context.save()
            context.beginPath()
            context.globalCompositeOperation = "destination-out";
            context.moveTo(x3, y3);
            context.lineTo(x5, y5);
            context.lineTo(x6, y6);
            context.lineTo(x4, y4);
            context.closePath();
            context.clip();
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.restore();
        }
    }

    // 鼠标松开事件
    const onmouseupHandle = function () {
        drawInfo.current.painting = false;
        canvasDraw();
        // @ts-ignore
        window.removeEventListener('mousemove', handleGlobalMouseMove);
        window.removeEventListener('mouseup', handleGlobalMouseUp);
    }

    // 下列代码时为了解决鼠标移出canvas画布时继续执行mousemove事件
    const handleGlobalMouseMove = (e: MouseEvent) => {
        onmousemoveHandle(e);
    };

    const handleGlobalMouseUp = () => {
        onmouseupHandle();
    };

    const onMouseDownWrapper = (e: React.MouseEvent) => {
        onmousedownHandle(e);
        // @ts-ignore
        window.addEventListener('mousemove', handleGlobalMouseMove);
        window.addEventListener('mouseup', handleGlobalMouseUp);
    };


    // 画线函数
    const drawLine = function (x1: number, y1: number, x2: number, y2: number) {
        if (context) {
            context.beginPath();
            context.lineWidth = drawInfo.current.lWidth;
            // context.globalAlpha = opacity;
            // 设置线条末端样式。
            context.lineCap = "round";
            // 设定线条与线条间接合处的样式
            context.lineJoin = "round";
            context.moveTo(x1, y1);
            context.lineTo(x2, y2);
            context.stroke();
            context.closePath();
        }
    }

    // 绘制方法
    const canvasDraw = function () {
        drawInfo.current.step++;
        if (drawInfo.current.step < drawInfo.current.canvasHistory.length) {
            drawInfo.current.canvasHistory.length = drawInfo.current.step;  // 截断数组
        }
        // 添加新的绘制到历史记录
        if (canvas) {
            drawInfo.current.canvasHistory.push(canvas.toDataURL());
        }
        if (drawInfo.current.step > 0) {
            setUndoActive(true);
        }
    }

    // 改变画笔颜色
    const getColor = (index: number, activeColor: string) => {
        setActiveColorIndex(index);
        if (context) {
            context.fillStyle = activeColor;
            context.strokeStyle = activeColor;
        }
    }

    // 实现清屏 + 添加history
    const reSetAddHistory = () => {
        setVillagePos([]);
        reSetCanvas();
        if (context && canvas && drawInfo.current.canvasHistory.length === 0) {
            drawInfo.current.canvasHistory.push(canvas.toDataURL());
            drawInfo.current.step = 0
            setUndoActive(true);
        };
    }

    // 实现清屏
    const reSetCanvas = () => {
        if (context && canvas) {
            context.clearRect(0, 0, canvas.width, canvas.height);
            drawInfo.current.canvasHistory = [];
            clearBg();
            setUndoActive(false);
            setRedoActive(false);
            // isRevision.current = false;
        }
    }

    // 重置背景
    const clearBg = function () {
        if (context && canvas) {
            // context.fillStyle = '#14a3fb';
            context.fillStyle = '#f9ecbd';
            context.fillRect(0, 0, 512, 512);
            // 下半部分黄色
            // context.fillStyle = '#f9ecbd';
            // context.fillRect(0, 256, 512, 256);
            // 重置案例图片
            props.imageListIndex(-1);
        }
    }

    // 下载图片
    const save = function () {
        if (canvas) {
            let imgUrl = canvas.toDataURL('image/png');
            let saveA = document.createElement('a');
            document.body.appendChild(saveA);
            saveA.href = imgUrl;
            saveA.download = 'Cybever_Map_' + Date.now().toString();
            saveA.target = '_blank';
            saveA.click();
        }
    }

    // const setWhiteBg = () => {
    //     if (context && canvas) {
    //         context.fillStyle = '#ffffff';
    //         context.fillRect(0, 0, 512, 512);
    //     }
    // }

    // 加载本地图片
    const loadImage = async function () {
        props.loadImage();
        // let status = props.taskTempStatus.current
        // if(status === 200 || status === 230 || status === 300){
        //     message.warning("Generating the model or high-quality scenes cannot upload new images.");
        //     return
        // }
        // await props.switchImg();
        // if (e.target.files?.length) {
        //     let image = await loadLocalImage(e.target.files[0]);
        //     if(image.width !== image.height){
        //         message.warning("The uploaded image's width and height ratio is not 1:1.");
        //         return
        //     }
        //     setWhiteBg();
        //     setFileInputKey(Date.now());

        //     if (context && canvas) {
        //         // 计算图片的缩放比例
        //         const scale = Math.min(canvas.width / image.width, canvas.height / image.height);
        //         // 计算缩放后的图片宽高
        //         const width = image.width * scale;
        //         const height = image.height * scale;
        //         // 计算绘制位置，以居中图片
        //         const x = (canvas.width - width) / 2;
        //         const y = (canvas.height - height) / 2;
        //         // 绘制缩放后的图片到 canvas
        //         context.drawImage(image, x, y, width, height);
        //         setUndoActive(false);
        //         setRedoActive(false);
        //         drawInfo.current.canvasHistory = [];
        //         drawInfo.current.step = 0;
        //         setVillagePos([]);
        //     }
        // }
    }


    // 撤销方法
    function canvasUndo() {
        if (context) {
            if (drawInfo.current.step > 0) {
                drawInfo.current.step--;

                let canvasPic = new Image();
                canvasPic.src = drawInfo.current.canvasHistory[drawInfo.current.step];
                canvasPic.onload = function () { context.drawImage(canvasPic, 0, 0); }
                setUndoActive(true);
                setRedoActive(true);

            } else {
                setUndoActive(false);
                message.warning("You can't undo any further.");
            }
        }
    }

    // 重做方法
    function canvasRedo() {
        if (context && !props.disableBrush) {
            if (drawInfo.current.step < drawInfo.current.canvasHistory.length - 1) {
                drawInfo.current.step++;
                let canvasPic = new Image();
                canvasPic.src = drawInfo.current.canvasHistory[drawInfo.current.step];
                canvasPic.onload = function () {
                    context.drawImage(canvasPic, 0, 0);
                }
                setRedoActive(true);
                setUndoActive(true);
            } else {
                message.warning("It's already the latest record.");
            }
        }
    }

    const addVillage = function (event: React.MouseEvent) {
        // props.taskTempStatus.current = 0;
        if (villagePos.length >= 3) {
            message.warning("You can only add up to 3 villages.");
            return
        }
        let canvas = document.getElementById("canvas") as HTMLCanvasElement;
        const bounds = canvas.getBoundingClientRect();
        let newPos = [...villagePos];
        newPos.push({
            vid: Date.now().toString(),
            x: (event.clientX - 12 - bounds.left) / bounds.width,
            y: (event.clientY - 12 - bounds.top) / bounds.height
        });
        setVillagePos(newPos);
    }

    const removeVillage = (vid: string) => {
        if (tool !== "remove") return
        let newVillagePos = villagePos.filter((item) => item.vid !== vid);
        // props.taskTempStatus.current = 0;
        setVillagePos(newVillagePos);
    }

    const removVillageTool = () => {
        setTool("remove");
    }

    const mouseDown = function (e: React.MouseEvent) {
        if (props.disableBrush) {
            message.warning("Cannot modify the map while generating models or UE scenes.");
            return
        }
        if (tool !== "pen") {
            tool === "add" && addVillage(e)
            tool === "remove" && removVillageTool()
            return
        }
        onMouseDownWrapper(e);
    }

    const mouseMove = (e: React.MouseEvent) => {
        if (!props.disableBrush) {
            onmousemoveHandle(e)
        }
    }

    const mouseUp = () => {
        if (!props.disableBrush) {
            onmouseupHandle()
        }
    }

    return (
        <>
            <div className="relative">
                <canvas id="canvas" width={512} height={512}
                    onMouseDown={mouseDown}
                    onMouseMove={mouseMove}
                    onMouseUp={mouseUp}
                ></canvas>
                {
                    villagePos.length > 0 && villagePos.map((pos) => {
                        return <img
                            src={villageIcon}
                            key={pos.vid} alt=""
                            width={24}
                            height={24}
                            className="absolute"
                            style={{ top: `${(pos.y * 100).toFixed(4)}%`, left: `${(pos.x * 100).toFixed(4)}%` }}
                            onClick={() => (removeVillage(pos.vid))}
                        />
                    })
                }
            </div>
            <div>
                <div className="tools text-white text-center py-2 flex flex-wrap max-w-[32rem] items-center">
                    <ul className="pen-color clearfix flex items-center">

                        <Tooltip placement="top" title={<span>Mountain</span>}>
                            <li
                                className={`color-item ${activeColorIndex === 0 ? 'active' : ''}`}
                                style={{ backgroundColor: "#9B9D9B" }}
                                onClick={() => getColor(0, "#9B9D9B")}
                            ></li>
                        </Tooltip>
                        <Tooltip placement="top" title={<span>Forest</span>}>
                            <li
                                className={`color-item ${activeColorIndex === 1 ? 'active' : ''}`}
                                style={{ backgroundColor: "#5CDB34" }}
                                onClick={() => getColor(1, "#5CDB34")}
                            ></li>
                        </Tooltip>
                        <Tooltip placement="top" title={<span>Water</span>}>
                            <li
                                className={`color-item ${activeColorIndex === 2 ? 'active' : ''}`}
                                style={{ backgroundColor: "#14A3FB" }}
                                onClick={() => getColor(2, "#14A3FB")}
                            ></li>
                        </Tooltip>
                        <Tooltip placement="top" title={<span>Land</span>}>
                            <li
                                className={`color-item ${activeColorIndex === 3 ? 'active' : ''}`}
                                style={{ backgroundColor: "#F9ECBD" }}
                                onClick={() => getColor(3, "#F9ECBD")}
                            ></li>
                        </Tooltip>
                    </ul>
                    <div className="flex items-center">
                        <Tooltip placement="top" title={<span>Pen</span>}>
                            <button className={`brush ${tool === "pen" ? "active" : ""}`} id="brush" title="pen" onClick={() => { setTool("pen") }}></button>
                        </Tooltip>
                        <Tooltip placement="top" title={<div>
                            <p>Add town</p>
                            <ul className="text-xs text-white opacity-50 list-disc ml-4">
                                <li>Max 3 settlements</li>
                                <li>Overlap can lead to incorrect generation</li>
                            </ul>
                        </div>}>
                            <button className={`add ${tool === "add" ? "active" : ""}`} title="village" onClick={() => { setTool("add") }}></button>
                        </Tooltip>
                        <Tooltip placement="top" title={<span>Delete town</span>}>
                            <button className={`remove ${tool === "remove" ? "active" : ""}`} title="village" onClick={() => { setTool("remove") }}></button>
                        </Tooltip>
                    </div>
                    <Tooltip placement="top" title={<span>Brush size</span>}>
                        <div className="flex items-center">
                            <span className=" w-2 h-2 bg-white rounded-full"></span>
                            <input
                                type="range"
                                id="range1"
                                min="1"
                                max="50"
                                value={rangValue}
                                onChange={rangeChange}
                                style={{ width: "240px" }}
                            />
                            <span className=" w-5 h-5 border-2 rounded-full"></span>
                        </div>
                    </Tooltip>
                    <div className="flex items-center my-2">
                        <Tooltip placement="top" title={<span>Undo</span>}>
                            <button className={`undo ${undoActive ? "active" : ""}`} id="undo" onClick={canvasUndo}></button>
                        </Tooltip>
                        <Tooltip placement="top" title={<span>Redo</span>}>
                            <button className={`redo ${redoActive ? "active" : ""}`} id="redo" onClick={canvasRedo}></button>
                        </Tooltip>
                        <Tooltip placement="top" title={<span>Save as file</span>}>
                            <button className="save" id="save" onClick={save}></button>
                        </Tooltip>
                        <Tooltip placement="top" title={<span>Upload save map</span>}>
                            <button className="load relative" id="load" onClick={() => loadImage()}>
                                {/* <input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => loadImage(e)} key={fileInputKey} className="file" /> */}
                                {/* <span className="file" ></span> */}
                                <span
                                    title="The uploaded image's width and height ratio needs to be 1:1, and the colors in the image must match the four colors we defined before generating the model."
                                    className=" absolute -top-2 -right-3 rounded-full border w-4 h-4 text-xs">?</span>
                            </button>
                        </Tooltip>
                        <Tooltip placement="top" title={<span>Reset</span>}>
                            <button className="clear" id="clear" onClick={() => reSetAddHistory()}></button>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </>
    );
});

export default DrawingBoard;