interface MaskProps {
  // 只需传入总尺寸
  size: number;
  // 可选的背景色和透明度
  backgroundColor?: string;
  opacity?: number;
}

export const Mask: React.FC<MaskProps> = ({
  size,
  backgroundColor = 'black',
  opacity = 0.6,
}) => {
  // 根据总尺寸决定镂空大小
  const holeSize = size === 750 ? 385 : 256;

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: "512px",
        height: "512px",
        pointerEvents: 'none',
        marginLeft: '26px',
        marginTop: '26px',
      }}
    >
      <svg
        width="100%"
        height="100%"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <defs>
          <mask id="hole">
            <rect width="100%" height="100%" fill="white" />
            <rect
              x={(512 - holeSize) / 2}
              y={(512 - holeSize) / 2}
              width={holeSize}
              height={holeSize}
              fill="black"
            />
          </mask>
        </defs>
        <rect
          width="100%"
          height="100%"
          fill={backgroundColor}
          opacity={opacity}
          mask="url(#hole)"
        />
      </svg>
    </div>
  );
};