export const BRUSH_SIZES = {
    xtiny: { key: '1', rate: 2, innerRadius: 1, outerRadius: 2 },
    tiny: { key: '2', rate: 3, innerRadius: 1.5, outerRadius: 2.5 },
    small: { key: '3', rate: 4, innerRadius: 2, outerRadius: 6 },
    medium: { key: '4', rate: 4, innerRadius: 5, outerRadius: 10 },
    large: { key: '5', rate: 3, innerRadius: 10, outerRadius: 16 },
};

export const TERRAIN_TOOLS = {
    ocean: { elevation: -0.25 },
    shallow: { elevation: -0.05 },
    valley: { elevation: +0.05 },
    mountain: { elevation: +1.0 },
    vaillage: { elevation: +0.5 },
    deleteVaillage: { elevation: -0.5 },
    clearVillage: { elevation: -0.5 },
};
