import { useState } from "react";
import "./GenerateStyle.css";

const styleImg = [{ src: "/image/example2.png", name: "风格1" }, { src: "/image/example2.png", name: "风格2" }, { src: "/image/example2.png", name: "风格3" }, { src: "/image/example2.png", name: "风格4" }];

function GenerateStyle({ serialNumber, clickImgHandler, isShowImageList }: { serialNumber: number, isShowImageList: boolean, clickImgHandler: (index: string) => void }) {
    const [selectedImg, setSelectedImg] = useState(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function clickImg(index: number) {
        setSelectedImg(index);
    }

    return (
        <div className="generate-style flex items-center rounded-md p-5">
            <div className="flex items-center text-lg mr-5">
                <span className="serial-number mr-2">{serialNumber}</span>
            </div>
            <div className="flex flex-1 items-center justify-between">
                <div className={`flex items-center space-x-4 style-img ${  !isShowImageList ? "hidden" : "" }`}>
                    {
                        styleImg.map((img, index) => (
                            <img src={img.src} alt={img.name} className={index === selectedImg ? "border-2 border-white" : ""} key={index} onClick={() => clickImg(index)} />
                        ))}
                </div>
                <div>
                    <button className="button-3d-preview rounded-md text-base" onClick={async () => {
                        setIsLoading(true);
                        await clickImgHandler(styleImg[selectedImg].name)
                        setIsLoading(false);
                    }}>
                        {isLoading ? "Generating" : "Generate 3D preview"}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GenerateStyle;