import React from "react";
import "./index.css";
import cx from "classnames";

interface TerrainAdjustmentProps {
  showOptions: boolean;
  onClose?: () => void;
}

const TerrainAdjustment: React.FC<TerrainAdjustmentProps> = ({
  showOptions,
}) => {
  //   const [showOptions, setShowOptions] = useState(false);

  return (
    <div
      className={cx("terrain-adjustment", {
        hide: !showOptions,
        show: showOptions,
      })}
    >
      <div className="content">
        <div id="sliders">{/* <button id="button-reset">Reset</button> */}</div>
      </div>
    </div>
  );
};

export default TerrainAdjustment;
