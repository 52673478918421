import { UploadProps } from "@/types";
import { ChangeEvent, useState } from "react";
import "./UploadMap.css";
import closeIcon from "@/assets/warning-close.png"
import uploadIcon from "@/assets/upload-m.png";
import { loadLocalImage } from "@/lib/utils";
import { checkAndConvertImageColorRange } from "@/lib/hsvColorConverter";

function UploadMap({ isVisible, onCancel, onContinue, text, item }: UploadProps) {
    if (!isVisible) return null;
    const [tip, setTip] = useState<string>("");
    const [previewImage, setPreviewImage] = useState<string | null>(null);
    const [isValidFile, setIsValidFile] = useState<boolean>(false);

    const close = () => {
        onCancel && onCancel();
    };

    const resetUploadState = () => {
        setTip("");
        setPreviewImage(null);
        setIsValidFile(false);
    };

    const uploadHandler = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) {
            console.log("No file selected or selection cancelled.");
            return;
        }
        resetUploadState();

        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(file.type)) {
            setTip("Images in this format are not supported, please use the following formats: .jpg, .jpeg, .png");
            return;
        }

        const maxSizeInBytes = 10 * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            setTip("File size exceeds 10MB. Please upload a smaller image.");
            return;
        }

        let image = await loadLocalImage(file);
        if (image.width !== image.height) {
            setTip("The image is not 1:1 ratio. Uploading may cause distortion");
            return
        }

        // 检查上传的图片是否符合 Cybever 的颜色图要求 
        const { outOfRangeRatio, canvas } = checkAndConvertImageColorRange(image);
        
        if (outOfRangeRatio > 0.1) {
            setTip("The image is not a colormap from Cybever");
            return
        }

        setPreviewImage(canvas.toDataURL());
        setIsValidFile(true);
    };

    const ContinueHandler = () => {
        if (!previewImage || !isValidFile) {
            setTip("Please upload a valid image first.");
            return;
        }
        
        onContinue(previewImage);
        close();
    };

    return (
        <div className="upload-map-container">
            <div className=" w-[30rem] upload-map rounded-md flex flex-col">
                <header className="flex justify-end">
                    <img src={closeIcon} alt="close" width={24} height={24} className="cursor-pointer" onClick={close} />
                </header>
                <main className="flex flex-col flex-1">
                    <div className="upload-input-container h-[14rem] my-4 flex justify-center items-center relative group">
                        <input
                            type="file"
                            onChange={(e) => uploadHandler(e)}
                            className="file absolute inset-0 opacity-0 z-50 cursor-pointer"
                            title="Upload and Search"
                        />
                        <div className={`absolute inset-0 z-20 flex flex-col items-center justify-center ${previewImage && "opacity-0 group-hover:opacity-100 transition-opacity duration-300"} bg-black bg-opacity-50`}>
                            <img src={uploadIcon} alt="upload" width={28} height={28} className="mb-2" />
                            <span className="text-white opacity-70 text-xl">Upload and Search</span>
                            <ul className="text-white opacity-70 text-sm mt-1 list-disc list-inside font-thin w-[80%]">
                                {item.length > 0 && item.map((txt, index) => (
                                    <li key={index}>{txt}</li>
                                ))}
                            </ul>
                        </div>
                        {previewImage && (
                            <div className="absolute z-10 inset-0 flex items-center justify-center">
                                <img src={previewImage} alt="Preview" className="max-w-full max-h-full object-contain" />
                            </div>
                        )}
                    </div>
                    <div>
                        <div className="text-base text-red-500 mb-2 min-h-14">
                            {tip}
                        </div>
                        {text}
                    </div>
                </main>
                <footer className="flex justify-end upload-footer">
                    <button onClick={close}>Cancel</button>
                    <button className="ml-4 continue" onClick={ContinueHandler}>Continue</button>
                </footer>
            </div>
        </div>
    );
};

export default UploadMap;