import { MapgenTypeEnums } from "@/types";

export const MAP_STYLE_TERRAIN: Record<string, number> = {
  "Forest Wetland": 0,
  "Snow Mountain": 1,
  "Gobi Desert": 2,
};

export const MAP_STYLE_TOWN: Record<string, number> = {
  "Water Village": 0,
  "Medieval Village": 1,
  "Wooden House": 2,
  "Farm House": 3,
};

export const ZOOM_5000 = 0.208;

export const ZOOM_1000 = 1.04;

export const ZOOM_750 = 1.38;

export const ZOOM_500 = 2;

export const DEFAULT_MAP_SIZE = 1000;

export const MAP_ZOOM: Record<number, number> = {
  500: ZOOM_500,
  750: ZOOM_750,
  1000: ZOOM_1000,
  5000: ZOOM_5000,
}

export const MAP_ZOOM_SIZE: Record<number, MapgenTypeEnums> = {
  500: MapgenTypeEnums.xtiny,
  750: MapgenTypeEnums.tiny,
  1000: MapgenTypeEnums.tiny,
  5000: MapgenTypeEnums.medium,
}