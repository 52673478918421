import request from "@/lib/http";
import { AxiosResponse } from "axios";

// 上传图片创建新任务
async function createTask(formData: FormData, map_type:number): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CREATE_TASK+ `?map_type=${map_type}`
    return request({ method: 'post', url, data: formData });
}

// 同一张图片修改时，不创建新的任务
function revisionTask(formData: FormData, taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_REVISION_TASK + taskID
    return request({ method: 'post', url, data: formData });
}

// 查询任务的状态
function taskStatus(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_STATUS + taskID;
    return request.get(url);
}

// 确定任务CONFIRM
function confirmTask(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CONFIRM_TASK + taskID;
    return request.get(url);
}

// 关闭任务
function closeTask(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_CLOSE_TASK + taskID;
    return request.get(url);
}

// 获取任务列表
function getTaskList(): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_LIST;
    return request.get(url);
}

// 获取villagefile
function getVillageFile(taskid: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_ASSET_FILE + taskid + "/data.json";
    return request.get(url);
}

// 删除任务
function removeTask(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_DELETE + taskID;
    return request.get(url);
}

// 获取任务列表
function taskRender(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_TASK_RENDER + taskID;
    return request.get(url);
}

// 获取模型的url
function getModelURL(taskID: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_ASSET_MODEL + taskID + "/scene.fbx";
    return request.get(url);
}

// 获取模型的url
function randomMap(): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_RANDOM_MAP;
    return request.get(url, { responseType: 'arraybuffer' });
}

type WaitingList = {
    name: string,
    organization_name: string,
    memo: string,
    role: string
}


function saveWaitList(data: WaitingList): Promise<AxiosResponse<any, any>> {    
    let url = import.meta.env.VITE_APP_JOIN_NOW;
    return request({
        method: 'post', url, data: JSON.stringify(data), headers: {
            'Content-Type': 'application/json',
        }
    });
}

function checkWaitList(): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_WAITUSER_CHECK;    
    return request(url);
}

// 获取模型的url
function getMapByText(text: string): Promise<AxiosResponse<any, any>> {
    let url = import.meta.env.VITE_APP_GENERATE_BY_TEXT;
    // return request.post(url, { responseType: 'arraybuffer' });
    return request({
        method: 'post', url, data: {
            "prompt": text
        }
    })
}

export {
    createTask,
    revisionTask,
    taskStatus,
    confirmTask,
    closeTask,
    getTaskList,
    removeTask,
    taskRender,
    getModelURL,
    randomMap,
    getMapByText,
    getVillageFile,
    checkWaitList,
    saveWaitList
};