import { BRUSH_SIZES, TERRAIN_TOOLS } from '../config/paint-config.ts';

// 类型定义
export type BrushSize = keyof typeof BRUSH_SIZES;
export type TerrainTool = keyof typeof TERRAIN_TOOLS;

interface BrushStateChangeListener {
    onToolChange?: (tool: TerrainTool) => void;
    onSizeChange?: (size: BrushSize) => void;
}
 class Brush {
    public currentTool: TerrainTool;
    public currentSize: BrushSize;
    private listeners: BrushStateChangeListener[] = [];

    constructor(initialTool: TerrainTool = 'mountain', initialSize: BrushSize = 'medium') {
        this.currentTool = initialTool;
        this.currentSize = initialSize;
    }

    // @ts-ignore
    private preventTouchStart = (event: TouchEvent) => {
        event.preventDefault();
    }

    // 工具相关方法
    public setTool(tool: TerrainTool): void {
        if (!TERRAIN_TOOLS[tool]) {
            console.warn(`Invalid tool: ${tool}`);
            return;
        }

        if (this.currentTool !== tool) {
            this.currentTool = tool;
            this.notifyToolChange();
        }
    }

    public getTool(): TerrainTool {
        return this.currentTool;
    }

    public getToolConfig() {
        return TERRAIN_TOOLS[this.currentTool];
    }

    // 尺寸相关方法
    public setSize(size: BrushSize): void {
        if (!BRUSH_SIZES[size]) {
            console.warn(`Invalid size: ${size}`);
            return;
        }

        if (this.currentSize !== size) {
            this.currentSize = size;
            this.notifySizeChange();
        }
    }

    public getSize(): BrushSize {
        return this.currentSize;
    }

    public getSizeConfig() {
        return BRUSH_SIZES[this.currentSize];
    }

    // 快捷键相关方法
    public handleKeyboardShortcut(key: string): boolean {
        // 处理画笔大小快捷键
        const sizeEntry = Object.entries(BRUSH_SIZES).find(([_, config]) => config.key === key);
        if (sizeEntry) {
            this.setSize(sizeEntry[0] as BrushSize);
            return true;
        }
        return false;
    }

    // 状态监听相关方法
    public addListener(listener: BrushStateChangeListener): void {
        this.listeners.push(listener);
    }

    public removeListener(listener: BrushStateChangeListener): void {
        const index = this.listeners.indexOf(listener);
        if (index > -1) {
            this.listeners.splice(index, 1);
        }
    }

    private notifyToolChange(): void {
        this.listeners.forEach(listener => {
            listener.onToolChange?.(this.currentTool);
        });
    }

    private notifySizeChange(): void {
        this.listeners.forEach(listener => {
            listener.onSizeChange?.(this.currentSize);
        });
    }

    // 工具状态检查方法
    public isValidTool(tool: string): tool is TerrainTool {
        return tool in TERRAIN_TOOLS;
    }

    public isValidSize(size: string): size is BrushSize {
        return size in BRUSH_SIZES;
    }

    // 获取所有可用选项
    public static getAvailableTools(): TerrainTool[] {
        return Object.keys(TERRAIN_TOOLS) as TerrainTool[];
    }

    public static getAvailableSizes(): BrushSize[] {
        return Object.keys(BRUSH_SIZES) as BrushSize[];
    }

    // 计算画笔实际参数
    public getCurrentBrushParameters() {
        const sizeConfig = this.getSizeConfig();
        const toolConfig = this.getToolConfig();

        return {
            ...sizeConfig,
            ...toolConfig,
            tool: this.currentTool,
            size: this.currentSize
        };
    }
}


export default new Brush();