import { onAuthStateChanged } from "firebase/auth";
import { auth } from "@/lib/firebase";
import { RefreshToken } from "@/types";

async function refreshToken() :Promise<RefreshToken>{
    return new Promise((resolve, reject) => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                console.log("login success!");
                user.getIdToken(true).then(token => {
                    resolve({ token, photoUrl: user.photoURL! });
                }).catch((error) => {
                    reject(error);
                });
            } else {
                console.log("user is logged out");
                reject("user is logged out");
            };
        });
    });
};

export { refreshToken }