/*
 * From https://www.redblobgames.com/maps/mapgen4/
 * Copyright 2018 Red Blob Games <redblobgames@gmail.com>
 * License: Apache v2.0 <http://www.apache.org/licenses/LICENSE-2.0.html>
 *
 * This module allows the user to paint constraints for the map generator
 */
'use strict';

/*
 * The painting interface uses a square array of elevations. As you drag the mouse it will paint filled circles into the elevation map,then send the elevation map to the generator to produce the output.
 * 
 * 绘制界面使用正方形的高程数组。当您拖动鼠标时，它会将填充的圆圈绘制到高程图中，然后将高程图发送到生成器以生成输出。
 */

import { createNoise2D } from 'simplex-noise';
//@ts-ignore
import { makeRandFloat } from '@redblobgames/prng';
 
// @ts-ignore
import  VillageManager  from '../plugin/village.ts';
import  Brush  from './brush.ts';
import { BRUSH_SIZES, TERRAIN_TOOLS } from "../config/paint-config.ts";

// @ts-ignore
import { Pos } from "@/types";

interface Size {
    innerRadius: number;
    outerRadius: number;
    rate: number;
}

interface Tool {
    elevation: number;
}

interface ElevationParam {
    seed: number;
    island: number;
}


interface CurrentStroke {
    previousElevation: Float32Array;
    time: Float32Array;
    strength: Float32Array;
}


/* The elevation is -1.0 to 0.0 → water, 0.0 to +1.0 → land */
// 海拔-1.0到0.0→水，0.0到+1.0→陆地
class Generator {
    private seed = 0;
    private island = 0;
    public userHasPainted = false;
    public elevation: Float32Array;
    public currentStroke:  CurrentStroke;
    private canvas: HTMLElement;
    private mapEl: HTMLElement;
    private mask: HTMLElement;
    // @ts-ignore
    private cb;
    // @ts-ignore
    private render;
    private dragging: boolean = false;
    private timestamp: number = 0;
    private brush:typeof Brush;
    public isAddDrawEvent: boolean = true;

    public actionType: string = "";


    public vaillagePos: Array<Pos> = [];
    public CANVAS_SIZE = 256;

    // @ts-ignore
    private villageManager: typeof VillageManager;
    private controls: [string, string, () => void][] = [];
    

    constructor(canvasElementId: string, mapElementId: string ) {

        // this.elevation = new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE);
        // this.currentStroke = this.initCurrentStroke();
        // this.canvas = document.getElementById(canvasElementId) as HTMLElement;
        // this.mapEl = document.getElementById(mapElementId) as HTMLElement;
        // this.mask = document.getElementById('ui-mask') as HTMLDivElement;
        // this.brush = Brush;
        // if (!this.canvas || !this.mapEl) {
        //     throw new Error(`Element with id ${canvasElementId} or ${mapElementId} not found.`);
        // };

        const getElement = <T extends HTMLElement>(id: string): T => {
            const element = document.getElementById(id);
            return element as T;
        };

        this.elevation = new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE);
        this.currentStroke = this.initCurrentStroke();
        this.canvas = getElement<HTMLElement>(canvasElementId);
        this.mapEl = getElement<HTMLElement>(mapElementId);
        this.mask = getElement<HTMLDivElement>('ui-mask');
        this.brush = Brush;

    }

    private initCurrentStroke() {
        return  {
            /* elevation before the current paint stroke began */
            previousElevation: new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE),
            /* how long, in milliseconds, was spent painting */
            time: new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE),
            /* maximum strength applied */
            strength: new Float32Array(this.CANVAS_SIZE * this.CANVAS_SIZE),
        };
    }

    public initEvent( render:any ,cb:()=>void, onWarning?: (message: string) => void) {
        this.render = render;
        this.cb = cb;
        this.villageManager =  VillageManager;
        this.villageManager.init(this.mapEl , onWarning);
        this._initListener();

    }
    private _initListener() {
        // this.brush.init(canvasElementId, mapElementId,cb,render);
         this.initControls();
         window.addEventListener('keydown', e => {
             for (let control of this.controls) {
                 if (e.key === control[0]) { control[2](); this.displayCurrentTool(); }
             }
         });
         
         for (let control of this.controls) {
             //@ts-ignore
             document.getElementById(control[1]).addEventListener('click', () => {
                 control[2]();
                 if (this.isAddDrawEvent && control[1] !== "vaillage" && control[1] !== "deleteVaillage") {
                     this.setUp('add');
                     this.isAddDrawEvent = false;
                 }
                 this.displayCurrentTool();
             });
         };
         this.setUp('add');
         this.displayCurrentTool();
         
     }
     private initControls() {

        const BRUSH_SIZES = {
            '1': { key: '1', name: 'xtiny', action: 'currentSize' },
            '2': { key: '2', name: 'tiny', action: 'currentSize' },
            '3': { key: '3', name: 'small', action: 'currentSize' },
            '4': { key: '4', name: 'medium', action: 'currentSize' },
            '5': { key: '5', name: 'large', action: 'currentSize' },
        } as const;

        const BRUSH_TOOLS = {
            'q': { key: 'q', name: 'ocean', action: 'currentTool' } as const,
            'w': { key: 'w', name: 'shallow', action: 'currentTool' } as const,
            'e': { key: 'e', name: 'valley', action: 'currentTool' } as const,
            'r': { key: 'r', name: 'mountain', action: 'currentTool' } as const,
            'p': { key: 'p', name: 'vaillage', action: 'currentTool', callback: () => this.addVaillage() } as const,
            't': { key: 't', name: 'deleteVaillage', action: 'currentTool', callback: () => this.removeVaillage() } as const,
            'y': { key: 'y', name: 'clearVillage', action: 'currentTool', callback: () => this.clearVillage() } as const,
        } satisfies Record<string, {
            key: string;
            name: string;
            action: string;
            callback?: () => void;
        }>;

        this.controls = [
            ...Object.values(BRUSH_SIZES).map(({ key, name }) => 
                [key, name, () => { this.brush.currentSize = name; }] as [string, string, () => void]),
            // @ts-ignore
            ...Object.values(BRUSH_TOOLS).map(({ key, name, callback }) => 
                [key, name, () => { 
                    this.brush.currentTool = name; 
                    callback?.();
                }] as [string, string, () => void])
        ];

        for (let control of this.controls) {
            //@ts-ignore
            document.getElementById(control[1]).addEventListener('click', () => {
                control[2]();
                this.displayCurrentTool();
            });
        }
    }

    public displayCurrentTool() {
        const className = 'current-control';
        for (let c of document.querySelectorAll("." + className)) {
            c.classList.remove(className);
        }
        //@ts-ignore
        document.getElementById(this.brush.currentTool).classList.add(className);
        //@ts-ignore
        document.getElementById(this.brush.currentSize).classList.add(className);
    }


    public setUp(action: 'add' | 'remove') {

        type EventMap = {
            'pointerdown': PointerEvent;
            'pointerup': PointerEvent;
            'pointercancel': PointerEvent;
            'pointermove': PointerEvent;
            'touchstart': TouchEvent;
        };

        const events: Array<[keyof EventMap, EventListener]> = [
            // @ts-ignore
            ['pointerdown', this.start],
            // @ts-ignore
            ['pointerup', this.end],
            // @ts-ignore
            ['pointercancel', this.end],
            // @ts-ignore
            ['pointermove', this.move],
            // @ts-ignore
            ['touchstart', this.preventTouchStart]
        ];

        events.forEach(([event, handler]) => {
            this.canvas[action === 'add' ? 'addEventListener' : 'removeEventListener'](event, handler);
        });

        if (action === 'add' || this.actionType === "delete") {
            this.mapEl.removeEventListener('click', VillageManager.addVillageHandler);
        }
    }

    private start = (event: PointerEvent) => {

        event.stopPropagation();
        if (event.button !== 0) return;

        this.canvas.setPointerCapture(event.pointerId);

        this.dragging = true;
        this.timestamp = Date.now();
        this.currentStroke.time.fill(0);
        this.currentStroke.strength.fill(0);
        this.currentStroke.previousElevation.set(this.elevation);
        this.move(event);
    }
    //@ts-ignore
    private end = (event: PointerEvent) => {
        this.dragging = false;
    }

    private move = (event: PointerEvent) => {

        if (!this.dragging) return;

        const nowMs = Date.now();
        const bounds = this.canvas.getBoundingClientRect();
        let coords = [
            (event.x - bounds.left) / bounds.width,
            (event.y - bounds.top) / bounds.height,
        ];

        // coords = Painting.screenToWorldCoords(coords);
        let out = this.render.screenToWorld(coords);
        coords =  [out[0] / 1000, out[1] / 1000];
        //@ts-ignore
        let brushSize = BRUSH_SIZES[this.brush.currentSize];
        if (event.pointerType === 'pen' && event.pressure !== 0.5) {
            let radius = 2 * Math.sqrt(event.pressure);
            brushSize = {
                key: brushSize.key,
                innerRadius: Math.max(1, brushSize.innerRadius * radius),
                outerRadius: Math.max(2, brushSize.outerRadius * radius),
                rate: brushSize.rate,
            };
        }
        if (event.shiftKey) {
            // Hold down shift to paint slowly
            brushSize = { ...brushSize, rate: brushSize.rate / 4 };
        }
        //@ts-ignore
        this.paintAt(TERRAIN_TOOLS[this.brush.currentTool], coords[0], coords[1],
            brushSize, nowMs - this.timestamp);
        this.timestamp = nowMs;
       // this.updateUI();
        if(this.cb) this.cb();
    }

    // private  updateUI() {
    //     let userHasPainted = this.userHasPainted();
    //     (document.querySelector("#slider-seed input") as HTMLInputElement).disabled = userHasPainted;
    //     (document.querySelector("#slider-island input") as HTMLInputElement).disabled = userHasPainted;
    //     (document.querySelector("#button-reset") as HTMLInputElement).disabled = !userHasPainted;
    // }

    private preventTouchStart = (event: TouchEvent) => {
        event.preventDefault();
    }


    public addVaillage() {
        this.setUp('remove');
        this.isAddDrawEvent = true;
        this.mapEl.addEventListener('click', this.villageManager.addVillageHandler);
    }

    // 替换原来的 removeVaillage 方法
    public removeVaillage() {
        this.actionType = "delete";
        this.setUp('remove');
        this.villageManager.enableVillageRemoval()
     
    }

    public onUpdate() {
        this.cb();
    }
    //@ts-ignore
    public clearVillage() {
        this.villageManager.clear();
    }

    // 获取村庄位置的新方法
    public getVillagePositions(): Array<Pos> {
        return this.villageManager.getVillages();
    }

    public disableAction() {
        this.mask.classList.add('ui-mask');
    }

    public enableAction() {
        this.mask.classList.remove('ui-mask');
    }
    //@ts-ignore   
    setElevationParam(elevationParam:ElevationParam) {
        if (elevationParam.seed !== this.seed || elevationParam.island !== this.island) {
            this.seed = elevationParam.seed;
            this.island = elevationParam.island;
            this.generate();
        }
    }

    /** Use a noise function to determine the shape */
    // 使用噪声函数来确定形状
    generate() {
        const { elevation, island } = this;
        const noise2D = createNoise2D(makeRandFloat(this.seed));
        
        const persistence = 1 / 2;
        const amplitudes = Array.from({ length: 5 }, (_, octave) => Math.pow(persistence, octave));
        //@ts-ignore
        function fbm_noise(nx, ny) {
            let sum = 0, sumOfAmplitudes = 0;
            for (let octave = 0; octave < amplitudes.length; octave++) {
                let frequency = 1 << octave;
                sum += amplitudes[octave] * noise2D(nx * frequency, ny * frequency);
                sumOfAmplitudes += amplitudes[octave];
            }
            return sum / sumOfAmplitudes;
        }

        for (let y = 0; y < this.CANVAS_SIZE; y++) {
            for (let x = 0; x < this.CANVAS_SIZE; x++) {
                let p = y * this.CANVAS_SIZE + x;
                let nx = 2 * x / this.CANVAS_SIZE - 1,
                    ny = 2 * y / this.CANVAS_SIZE - 1;
                let distance = Math.max(Math.abs(nx), Math.abs(ny));
                let e = 0.5 * (fbm_noise(nx, ny) + island * (0.75 - 2 * distance * distance));
                if (e < -1.0) { e = -1.0; }
                if (e > +1.0) { e = +1.0; }
                elevation[p] = e;
                if (e > 0.0) {
                    let m = (0.5 * noise2D(nx + 30, ny + 50)
                        + 0.5 * noise2D(2 * nx + 33, 2 * ny + 55));
                    // TODO: make some of these into parameters
                    let mountain = Math.min(1.0, e * 5.0) * (1 - Math.abs(m) / 0.5);
                    if (mountain > 0.0) {
                        elevation[p] = Math.max(e, Math.min(e * 3, mountain));
                    }
                }
            }
        }

        this.userHasPainted = false;
    }

    /**
     * Paint a circular region. x0, y0 should be 0 to 1
     * 绘制一个圆形区域。x0， y0应该是0到1
     */
    paintAt(
        tool: Tool,
        x0: number, 
        y0: number,
        size: Size,
        deltaTimeInMs: number
    ) :void {
        let { elevation } = this;
        /* This has two effects: first time you click the mouse it has a strong effect, and it also limits the amount in case you pause
         * 
         *  */
        deltaTimeInMs = Math.min(100, deltaTimeInMs);

        let newElevation = tool.elevation;
        let { innerRadius, outerRadius, rate } = size;
        let xc = (x0 * this.CANVAS_SIZE) | 0, yc = (y0 * this.CANVAS_SIZE) | 0;
        let top = Math.ceil(Math.max(0, yc - outerRadius)),
            bottom = Math.floor(Math.min(this.CANVAS_SIZE - 1, yc + outerRadius));
        for (let y = top; y <= bottom; y++) {
            let s = Math.sqrt(outerRadius * outerRadius - (y - yc) * (y - yc)) | 0;
            let left = Math.max(0, xc - s),
                right = Math.min(this.CANVAS_SIZE - 1, xc + s);
            for (let x = left; x <= right; x++) {
                let p = y * this.CANVAS_SIZE + x;
                let distance = Math.sqrt((x - xc) * (x - xc) + (y - yc) * (y - yc));
                let strength = 1.0 - Math.min(1, Math.max(0, (distance - innerRadius) / (outerRadius - innerRadius)));
                let factor = rate / 1000 * deltaTimeInMs;
                this.currentStroke.time[p] += strength * factor;
                if (strength > this.currentStroke.strength[p]) {
                    this.currentStroke.strength[p] = (1 - factor) * this.currentStroke.strength[p] + factor * strength;
                }
                let mix = this.currentStroke.strength[p] * Math.min(1, this.currentStroke.time[p]);
                elevation[p] = (1 - mix) * this.currentStroke.previousElevation[p] + mix * newElevation;
            }
        }

        // console.log("elevation:", elevation);
        this.userHasPainted = true;
    }
}
export default  Generator;

