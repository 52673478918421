// @ts-nocheck
/*
 * From http://www.redblobgames.com/maps/mapgen4/
 * Copyright 2018 Red Blob Games <redblobgames@gmail.com>
 * License: Apache v2.0 <http://www.apache.org/licenses/LICENSE-2.0.html>
 *
 * This module uses webgl+regl to render the generated maps
 */

import {vec2, vec4, mat4} from 'gl-matrix';
import Geometry from "../algorithm/geometry.ts";
import type {Mesh} from "../types.d.ts";
//@ts-ignore
import REGL from 'regl/dist/regl.js';

import Drawer from "./shader.ts";
import Painting from "./painting.ts";
import {ParamManager} from "./paramManager.ts";
import param from "../config/index.ts";

interface RenderParameters {
    outline_water: number;
    tilt_deg: number;
    rotate_deg: number;
    zoom: number;
    x: number;
    y: number;
    mountain_height: number;
    light_angle_deg: number;
    slope: number;
    flat: number;
    ambient: number;
    overhead: number;
    outline_depth: number;
    outline_coast: number;
    outline_strength: number;
    outline_threshold: number;
    biome_colors: number[];
}

interface DrapeParams {
    elements: REGL.Elements;
    a_xy: REGL.Buffer;
    a_em: REGL.Buffer;
    u_water: REGL.Texture2D;
    u_depth: REGL.Texture2D;
    u_projection: mat4;
    u_light_angle: [number, number];
    u_slope: number;
    u_flat: number;
    u_ambient: number;
    u_overhead: number;
    u_outline_depth: number;
    u_outline_coast: number;
    u_outline_water: number;
    u_outline_strength: number;
    u_outline_threshold: number;
    u_biome_colors: number[];
}
class Renderer {

    private regl:REGL.Regl;
    private river_texturemap:REGL.Texture2D;
    private fbo_texture_size:number = 0;
    numRiverTriangles: number = 0;
    public screenshotCanvas: HTMLCanvasElement;
    // fbo_land_texture:REGL.Texture2D;
    // fbo_land:REGL.Framebuffer2D;

    public screenshotCallback: () => void;
    public renderParam: RenderParameters | null = null;
    public painting:Painting;
    private mountainHeight:number;
    el:string;
    zoom: number;

    private readonly fbos: {
        final: REGL.Framebuffer2D;
        land: REGL.Framebuffer2D;
        river: REGL.Framebuffer2D;
        depth: REGL.Framebuffer2D;
        z: REGL.Framebuffer2D;
    };

    // 2. 纹理相关
    private readonly textures: {
        land: REGL.Texture2D;
        river: REGL.Texture2D;
        final: REGL.Texture2D;
        depth: REGL.Texture2D;
        z: REGL.Texture2D;
    };

    // 3. 几何数据相关
    private readonly geometryBuffers: {
        a_quad_xy: Float32Array;
        a_quad_em: Float32Array;
        quad_elements: Int32Array;
        a_river_xyuv: Float32Array;
    };

    private readonly buffers: {
        quad_xy: REGL.Buffer
        quad_em: REGL.Buffer;
        quad_elements: REGL.Elements;
        river_xyuv: REGL.Buffer;
    };
    
    constructor (el:string ,mesh: Mesh,painting:Painting) {

        if(!el)  throw new Error("container ele can not be null");
        if(!mesh)  throw new Error("generated mesh can not be null");

        this.el = el;
        this.mesh = mesh;

        this._initREGL();
        this.resizeCanvas();

        this.textures = this.initTextures();
        this.renderState = this.initRenderState();
        this.fbos = this.initFramebuffers();

        this.geometryBuffers = this.initGeometryBuffers();
        this.buffers = this.initBuffers();
        this.drawer = new Drawer(this.regl);
        this.painting = painting;

        this.initScreenshotCanvas();
        this.initParamManager();

        //this.renderParam = undefined;
        this.startDrawingLoop();
    }

    _initREGL(){
        this.regl = REGL({
            canvas: `#${this.el}` || "#mapgen4",
            extensions: ['OES_element_index_uint']
        });

        this.river_texturemap = this.regl.texture({data: Geometry.createRiverBitmap(), mipmap: 'nice', min: 'mipmap', mag: 'linear', premultiplyAlpha: true});
        this.fbo_texture_size = 2048;
    }

    private initFramebuffers(): typeof this.fbos {

        const createFbo = (texture: REGL.Texture2D) => this.regl.framebuffer({
            color: texture,
            depth: true
        });
    
        const fboKeys = ['final', 'land', 'river', 'depth'] as const;
        
        return Object.fromEntries(
            fboKeys.map(key => [key, createFbo(this.textures[key])])
        ) as typeof this.fbos;
    }

    private initRenderState(): typeof this.renderState {
        const topdown = mat4.create();
        mat4.translate(topdown, topdown, [-1, -1, 0]);
        mat4.scale(topdown, topdown, [1/500, 1/500, 1]);
        return {
            projection: mat4.create(),
            inverseProjection: mat4.create(),
            topdown,
            numRiverTriangles: 0
        };
    }

    private initTextures(): typeof this.textures {

        this.textureSize = 2048;
        this.river_texturemap = this.regl.texture({
            data: Geometry.createRiverBitmap(),
            mipmap: 'nice', 
            min: 'mipmap', 
            mag: 'linear', 
            premultiplyAlpha: true
        });
        const _createTexture = (): REGL.Texture2D => {
            return this.regl.texture({
                width: this.textureSize, 
                height: this.textureSize, 
                min: 'linear', 
                mag: 'linear'
            });
        }
    
        const textureKeys = ['land', 'river', 'final', 'depth', 'z'] as const;
        
        return Object.fromEntries(
            textureKeys.map(key => [key, _createTexture()])
        ) as typeof this.textures;
    }

    private initScreenshotCanvas(): void {
        this.screenshotCanvas = document.createElement('canvas');
        // const textureSize = this.getTextureSize();

        const size :{width:number,height:number} = {
            width: this.textureSize,
            height: this.textureSize,
        };
        Object.assign(this.screenshotCanvas, size);
        //@ts-ignore
        this.screenshotCallback = null;
    }

    private initGeometryBuffers(): typeof this.geometryBuffers {
        // Geometry.setMeshGeometry(mesh, this.geometryBuffers.a_quad_xy);
        const buffers = {
            a_quad_xy: new Float32Array(2 * (this.mesh.numRegions + this.mesh.numTriangles)),
            a_quad_em: new Float32Array(2 * (this.mesh.numRegions + this.mesh.numTriangles)),
            a_river_xyuv: new Float32Array(1.5 * 3 * 4 * this.mesh.numSolidTriangles),
            quad_elements: new Int32Array(3 * this.mesh.numSolidSides)
        };
    
        Geometry.setMeshGeometry(this.mesh, buffers.a_quad_xy);
        return buffers;
    }
    private initBuffers(): typeof this.buffers {
        return {
            quad_xy: this.regl.buffer({           
                 usage: 'static',
                type: 'float',
                data: this.geometryBuffers.a_quad_xy,
                }),
            quad_em: this.regl.buffer({
                usage: 'dynamic',
                type: 'float',
                length: 4 * this.geometryBuffers.a_quad_em.length,
            }),
            quad_elements: this.regl.elements({
                primitive: 'triangles',
                usage: 'dynamic',
                type: 'uint32',
                length: 4 * this.geometryBuffers.quad_elements.length,
                count: this.geometryBuffers.quad_elements.length,
            }),
            river_xyuv: this.regl.buffer({
                usage: 'dynamic',
                type: 'float',
                length: 4 * this.geometryBuffers.a_river_xyuv.length,
            }),
        }
    }

    screenToWorld(coords: [number, number]): vec2 {
        /* convert from screen 2d (inverted y) to 4d for matrix multiply */
        let glCoords = vec4.fromValues(
            coords[0] * 2 - 1,
            1 - coords[1] * 2,
            /* TODO: z should be 0 only when tilt_deg is 0;
             * need to figure out the proper z value here */
            0,
            1
        );
        /* it returns vec4 but we only need vec2; they're compatible */
        let transformed = vec4.transformMat4(vec4.create(), glCoords, this.renderState.inverseProjection);
        return [transformed[0], transformed[1]];
    }

    private initParamManager() {
        this.paramManager = new ParamManager();
        console.log(this.paramManager.getAllParams());
        //this.renderParam = this.paramManager.getAllParams();
    }

    /* Allow drawing at a different resolution than the internal texture size */
    private resizeCanvas() {
        // this.el = this.el || 'mapgen4'
        let canvas = document.getElementById(`${this.el}`) as HTMLCanvasElement;
        let size = canvas.clientWidth;
        size = 2048; /* could be smaller to increase performance */
        if (canvas.width !== size || canvas.height !== size) {
            console.log(`Resizing canvas from ${canvas.width}x${canvas.height} to ${size}x${size}`);
            canvas.width = canvas.height = size;
            this.regl.poll();
        }
    }

    private clearBuffers() {
        // I don't have to clear fbo_em because it doesn't have depth
        // and will be redrawn every frame. I do have to clear
        // fbo_river because even though it doesn't have depth, it
        // doesn't draw all triangles.
        this.fbos.river.use(() => {
            this.regl.clear({color: [0, 0, 0, 0]});
        });
        this.fbos.depth.use(() => {
            this.regl.clear({color: [0, 0, 0, 1], depth: 1});
        });
        this.fbos.final.use(() => {
            this.regl.clear({color: [0.3, 0.3, 0.35, 1], depth: 1});
        });
    }

    private calculateDrapeParams(currentRenderParam: RenderParameters): DrapeParams {
        // 计算光照角度
        const lightAngleRad = Math.PI/180 * (currentRenderParam.light_angle_deg + currentRenderParam.rotate_deg);
        
        return {
            // 几何相关参数
            elements: this.buffers.quad_elements,
            a_xy: this.buffers.quad_xy,
            a_em: this.buffers.quad_em,
            
            // 纹理相关参数
            u_water: this.textures.river,
            u_depth: this.textures.depth,
            u_projection: this.renderState.projection,
            
            // 光照相关参数
            u_light_angle: [
                Math.cos(lightAngleRad),
                Math.sin(lightAngleRad)
            ],
            u_slope: currentRenderParam.slope,
            u_flat: currentRenderParam.flat,
            u_ambient: currentRenderParam.ambient,
            u_overhead: currentRenderParam.overhead,
            
            // 轮廓相关参数
            u_outline_depth: currentRenderParam.outline_depth * 5 * currentRenderParam.zoom,
            u_outline_coast: currentRenderParam.outline_coast,
            u_outline_water: currentRenderParam.outline_water,
            u_outline_strength: currentRenderParam.outline_strength,
            u_outline_threshold: currentRenderParam.outline_threshold / 1000,
            
            // 生物群系颜色
            u_biome_colors: currentRenderParam.biome_colors,
        };
    }

    private captureScreenshot(): void {
        if (!this.screenshotCallback || !this.screenshotCanvas) return;

        try {
            const context: ScreenshotContext = {
                gl: this.regl._gl,
                canvas: this.screenshotCanvas,
                textureSize: this.fbo_texture_size
            };

            this.processScreenshotData(context);
            
            // 执行回调并清理
            this.screenshotCallback();
            this.screenshotCallback = null;

        } catch (error) {
            console.error('Screenshot capture failed:', error);
            this.screenshotCallback = null;
        }
    }

    private processScreenshotData(context: ScreenshotContext): void {
        const { gl, canvas, textureSize } = context;
        
        // 获取 2D 上下文
        const ctx = canvas.getContext('2d', { willReadFrequently: true });
        if (!ctx) throw new Error('Failed to get 2D context');

        // 创建图像数据
        const imageData = ctx.getImageData(0, 0, textureSize, textureSize);
        const bytesPerRow = 4 * textureSize;
        const buffer = new Uint8Array(bytesPerRow * textureSize);

        // 读取像素数据
        gl.readPixels(
            0, 0, 
            textureSize, textureSize, 
            gl.RGBA, 
            gl.UNSIGNED_BYTE, 
            buffer
        );

        // 翻转行顺序 (WebGL 到 Canvas)
        this.flipImageRows(imageData, buffer, textureSize, bytesPerRow);

        // 将数据写回画布
        ctx.putImageData(imageData, 0, 0);
    }

    private flipImageRows(
        imageData: ImageData, 
        buffer: Uint8Array, 
        textureSize: number, 
        bytesPerRow: number
    ): void {
        for (let y = 0; y < textureSize; y++) {
            const rowBuffer = new Uint8Array(
                buffer.buffer, 
                y * bytesPerRow, 
                bytesPerRow
            );
            imageData.data.set(
                rowBuffer, 
                (textureSize - y - 1) * bytesPerRow
            );
        }
    }
    //@ts-ignore
    startDrawingLoop(exportImage: boolean = false ) {

        const exportValue = exportImage ? 1 : 0;
        // console.log("exportValue:",exportValue)
        /* draw rivers to a texture, which will be draped on the map surface */

        // /* write 16-bit elevation to a texture's G,R channels; the B,A channels are empty */

        /* using the same perspective as the final output, write the depth
        to a texture, G,R channels; used for outline shader */



        /* Only draw when render parameters have been passed in;
         * otherwise skip the render and wait for the next tick */
        this.clearBuffers();
        //@ts-ignore
        this.regl.frame(_context => {

            const currentRenderParam = this.renderParam;
        
            if (!currentRenderParam || Object.keys(currentRenderParam).length === 0) { return; }
            // console.log({renderParam})
            this.renderParam = null;

            if (this.numRiverTriangles > 0) {
                this.drawer.drawRivers(this.fbos.river , this.river_texturemap,{
                    count: 3 * this.numRiverTriangles,
                    a_xyuv: this.buffers.river_xyuv,
                    u_projection: this.renderState.topdown,
                });
            }
            this.drawer.drawLand(this.fbos.land ,{
                elements: this.buffers.quad_elements,
                a_xy: this.buffers.quad_xy,
                a_em: this.buffers.quad_em,
                u_projection: this.renderState.topdown,
                u_water: this.textures.river,
                u_outline_water: currentRenderParam.outline_water,
                export_value: exportValue,
            });

            //定义旋转后的投影效果，主要应对与sliders中的title_deg和rolate_deg
            /* Standard rotation for orthographic view */
            mat4.identity(this.renderState.projection);
            mat4.rotateX(this.renderState.projection, this.renderState.projection, (180 + currentRenderParam.tilt_deg) * Math.PI/180);
            mat4.rotateZ(this.renderState.projection, this.renderState.projection, currentRenderParam.rotate_deg * Math.PI/180);
            
            /* Top-down oblique copies column 2 (y input) to row 3 (z
             * output). Typical matrix libraries such as glm's mat4 or
             * Unity's Matrix4x4 or Unreal's FMatrix don't have this
             * this.renderState.projection built-in. For mapgen4 I merge orthographic
             * (which will *move* part of y-input to z-output) and
             * top-down oblique (which will *copy* y-input to z-output).
             * <https://en.wikipedia.org/wiki/Oblique_projection> */
            this.renderState.projection[9] = 1;
            
            /* Scale and translate works on the hybrid this.renderState.projection */

            // renderParam.zoom = zoom || renderParam.zoom
            // renderParam.zoom = 0.9
            mat4.scale(this.renderState.projection, this.renderState.projection, [currentRenderParam.zoom/100, currentRenderParam.zoom/100, currentRenderParam.mountain_height * currentRenderParam.zoom/100]);
            mat4.translate(this.renderState.projection, this.renderState.projection, [-currentRenderParam.x, -currentRenderParam.y, 0]);

            /* Keep track of the inverse matrix for mapping mouse to world coordinates */
            mat4.invert(this.renderState.inverseProjection, this.renderState.projection);
            
            //添加轮廓，增加3d渲染效果

            if (currentRenderParam.outline_depth > 0) {
                this.drawer.drawDepth(this.fbos.depth,{
                    elements: this.buffers.quad_elements,
                    a_xy: this.buffers.quad_xy,
                    a_em: this.buffers.quad_em,
                    u_projection: this.renderState.projection
                });
            }
            //将地形纹理图层渲染在整个地形表面上，实现类似“披覆”的效果，以增强地图的视觉细节。
            //它通过渲染到特定纹理上，使得地形和纹理图层更好地结合。这通常用在应用光影、颜色等效果上，
            //以呈现地形的起伏和高低差异。结合 WebGL 渲染管线，该函数使用了 regl 库定义的绘制参数，
            //例如顶点着色器、片段着色器和各项渲染配置，以实现高效渲染
            this.drawer.drawDrape(
                this.fbos.final, 
                this.textures.land, 
                this.textureSize, 
                this.calculateDrapeParams(currentRenderParam)
            );
                    /* draw the high resolution final output to the screen, smoothed and resized */

            /* draw the final image by draping the biome colors over the geometry;
            note that u_depth and u_mapdata are both encoded with G,R channels
            for 16 bits */
            this.drawer.drawFinal(this.textures, {
                u_offset: [0.5 / this.fbo_texture_size, 0.5 / this.fbo_texture_size],
                export_value: exportValue,
            });

            if (this.screenshotCallback) {
                // TODO: regl says I need to use preserveDrawingBuffer
                this.captureScreenshot();
            }

            this.clearBuffers();
        });
    }

    public setZoom(zoom) {

        this.zoom = zoom;
         
        const BRUSH_SIZES = {
            MEDIUM: { min: 0.208, max: 1.04, size: "medium" },
            TINY: { min: 1.04, max: 2, size: "tiny" },
            XTINY: { min: 2, max: Infinity, size: "xtiny" }  // 默认值
        } as const;

        this.painting.brush.currentSize = Object.values(BRUSH_SIZES).find(
            ({ min, max }) => zoom >= min && zoom < max
        )?.size || "small";

        const ZOOM_SETTINGS = {
            MEDIUM: {
                range: { min: 0.2, max: 0.85 },
                params: {
                    outline_depth: 1,
                    outline_strength: 10,
                    mountain_height: 70
                }
            },
            FAR: {
                range: { min: 1, max: Infinity },
                params: {
                    outline_depth: 0.2,
                    outline_strength: 5,
                    mountain_height: 30
                }
            }
        } as const;
        
        const zoomSetting = Object.values(ZOOM_SETTINGS).find(
            ({ range }) => this.zoom > range.min && this.zoom < range.max
        );
        
        if (zoomSetting) {
            Object.assign(param.render, zoomSetting.params);
            this.updateView(param.render);
        }
    }
    /* Update the buffers with the latest map data */
    public updateMap() {
        this.buffers.quad_em.subdata(this.geometryBuffers.a_quad_em);
        this.buffers.quad_elements.subdata(this.geometryBuffers.quad_elements);
        this.buffers.river_xyuv.subdata(this.geometryBuffers.a_river_xyuv.subarray(0, 4 * 3 * this.numRiverTriangles));
    }

    public updateView(renderParam: any) {
        if (!renderParam) return;

        // 创建新的渲染参数对象
        this.renderParam = {
            ...renderParam,
            // 如果存在自定义缩放，则使用自定义缩放
            zoom: this.zoom || renderParam.zoom,
        };

        // 更新山体高度
        if (renderParam.mountain_height) {
            this.mountainHeight = renderParam.mountain_height;
        }
    }

    public getMountainHeight(){
        return  this.mountainHeight;
    }

    public generateHeightMap(){
        this.startDrawingLoop(true);
        this.updateView(param.render);
    }

    public generateOriginalMap(){
        this.startDrawingLoop(false);
        this.updateView(param.render);
    }

    public setScreenshotCallback(callback: () => void) {
        this.screenshotCallback = callback;
    }

    public getScreenshotCanvas() {
        return this.screenshotCanvas;
    }

    public stopDrawingLoop() {
        if (this.renderLoop) {
            this.regl.cancelFrame(this.renderLoop);
            this.renderLoop = null;
        }
    }

    public dispose() {
        this.stopDrawingLoop();
        //@ts-ignore
        this.screenshotCallback = null;
        this.renderParam = undefined;
    }
}

export default Renderer;