import MapGen from "../index.ts";

abstract class BasePlugin {
    protected mapgen: MapGen | null = null;
    
    /**
     * 插件初始化方法
     */
    public abstract onInit(mapgen: MapGen): Promise<void>;
    
    /**
     * 插件销毁方法
     */
    public abstract dispose(): void;
    
    /**
     * 获取地图生成器实例
     */
    protected getMapgen(): MapGen {
        if (!this.mapgen) {
            throw new Error('Mapgen instance not initialized');
        }
        return this.mapgen;
    }
}

export default BasePlugin;