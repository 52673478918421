import { UIKeyBind, SingleKey, TurboKey, KEY_BUTTONS, MOVE_ACTIONS, VIEW_ACTIONS } from "./keyctrl";

class UEPlayer {

    constructor({ video, videoUrl, map, mapUrl, move, weather, onActive, onLoss }) {
        this.video = video;
        this.videoUrl = videoUrl;
        this.map = map;
        this.mapUrl = mapUrl;
        this.move = move;
        this.weather = weather;
        this.onActive = onActive;
        this.onLoss = onLoss;
        this.ws = null;
        this.frameQueue = [];
        this.singleKey = null;
        this.turboKey = null;
        this.streamTime = 0;
        this.streamTimer = null;
        this.heartbeatTimer = null;
        this.location = [0, 0];
        this.initCtrl();
        this.initWeather();
        this.initMiniMap();
        this.initHeartbeat();
        this.open();
    }

    // Init move/view controller
    initCtrl() {

        // Move Controller
        this.singleKey = new SingleKey(Object.keys(MOVE_ACTIONS));
        this.singleKey.onKeydown(key => {
            this.sendAction(MOVE_ACTIONS[key], 5);
        });
        this.singleKey.onKeyup(key => {
            this.sendAction(MOVE_ACTIONS[key] + "_done", 5);
        });

        // View Controller
        this.turboKey = new TurboKey(Object.keys(VIEW_ACTIONS));
        this.turboKey.onKey(key => {
            this.sendAction(VIEW_ACTIONS[key], 5);
        });

        // UI key bind
        this.keyBind = new UIKeyBind();
        for (let btn of this.move.getElementsByTagName("button")) {
            let keyBtn = KEY_BUTTONS[btn.textContent];
            if (keyBtn) {
                let [key, code] = keyBtn;
                this.keyBind.addBind(btn, key, code);
            }
        }
    }

    // Init weather controller
    initWeather() {
        let container = this.weather;
        let detailsElement = container.querySelector('details');
        let time = container.querySelector(".time input[type='range']");
        let weather = container.querySelectorAll(".weather input[type='radio']");
        let weatherLevel = container.querySelector(".weather input[type='range']");
        let wind = container.querySelector(".wind input[type='checkbox']");
        let windLevel = container.querySelector(".wind input[type='range']");
        let confirm = container.querySelector("button:last-of-type");
        let handler = () => {
            let data = {
                "task_id": "beijing_test",
                "command": "control_weather",
                "time": 1.0,
                "wind_force": 0,
                "precipitations_type": "",
                "precipitations_force": 0
            }
            data.time = parseFloat(time.value);
            for (let w of weather) {
                if (w.checked) {
                    data.precipitations_type = w.value;
                    data.precipitations_force = parseInt(weatherLevel.value);
                    break;
                }
            }
            if (wind.checked) data.wind_force = parseInt(windLevel.value);
            this.send(data);
            this.active();
            detailsElement.open = false;
        }
        confirm.addEventListener("click", handler);
        this.weatherDestroy = () => {
            confirm.removeEventListener("click", handler);
        }
    }

    // Init mini map
    initMiniMap() {
        this.map.innerHTML = "";
        let img = document.createElement("img");
        img.src = this.mapUrl;
        this.map.appendChild(img);
        this.b = document.createElement("b");
        this.map.appendChild(this.b);
        this.i = document.createElement("i");
        this.i.innerText = "V";
        this.b.appendChild(this.i);
    }

    // Send heartbeat to server
    initHeartbeat() {
        this.heartbeatTimer = setInterval(() => {
            this.send({ "command": "ping" });
        }, 1000 * 10);
    }

    // Check alive from stream time
    initStreamAlive() {
        if (this.streamTimer) return;
        this.streamTime = Date.now();
        this.streamTimer = setInterval(async () => {
            let lossTime = Date.now() - this.streamTime;
            if (lossTime > 1000 * 20) this.loss();
        }, 1000);
    }

    // Refresh location
    refreshLocation(data) {
        let [x0, y0] = this.location;
        let [x, y] = data;
        if (x == x0 && y == y0) return;
        let r = Math.atan2(y - y0, x - x0);
        let d = r * (180 / Math.PI) - 90;
        this.location = [x, y];
        let left = (x + 1) * 100 / 2 + "%";
        let top = (y + 1) * 100 / 2 + "%";
        this.b.style.left = left;
        this.b.style.top = top;
        this.i.style.transform = `rotate(${d.toFixed()}deg)`;
    }

    // Trigger active
    active() {
        this.onActive && this.onActive.call(null);
    }

    // Loss stream
    loss() {
        this.destroy();
        this.onLoss && this.onLoss.call(null);
    }

    // Send move/view control
    sendAction(action, speed) {
        this.send({ "command": "move", action, speed });
        this.active();
    }

    // Send websocket data
    send(data) {
        try {
            if (!this.ws) return;
            if (this.ws.readyState != WebSocket.OPEN) return;
            console.log(data);
            this.ws.send(JSON.stringify(data));
        } catch (e) {}
    }

    // Handle command from server
    handleCommand(text) {
        let data = null;
        try {
            data = JSON.parse(text);
        } catch(e) {
            console.error("Command parse error", text);
            return;
        }
        // Location
        if (data.location) {
            this.refreshLocation(data.location);
        }
    }

    // open websocket connection
    open() {
        let sourcebuffer = null;
        this.ws = new WebSocket(this.videoUrl);
        this.ws.binaryType = "arraybuffer";
        let firstMessage = true;

        let demux_moov = function(info) {
            console.log("demux moov");
            let codecs = [];
            for (let i = 0; i < info.tracks.length; i++) {
                codecs.push(info.tracks[i].codec);
            }
            console.log(codecs);
            let video = this.video;
            let mediasource = new MediaSource();
            video.src = URL.createObjectURL(mediasource);
            let pre_pos = 0;
            mediasource.onsourceopen = function() {
                sourcebuffer = mediasource.addSourceBuffer('video/mp4; codecs="' + codecs.join(', ') + '"');
                sourcebuffer.onupdateend = function() {
                    let pos = video.currentTime;
                    if (video.buffered.length > 0) {
                        let start = video.buffered.start(video.buffered.length - 1);
                        let end = video.buffered.end(video.buffered.length - 1);
                        if (pos < start) video.currentTime = start;
                        if (pos > end) video.currentTime = start;
                        if (pos - pre_pos != 0 && end - pos > 1) video.currentTime = end;
                        for (let i = 0; i < video.buffered.length - 1; i++) {
                            let prestart = video.buffered.start(i);
                            let preend = video.buffered.end(i);
                            if (!sourcebuffer.updating) sourcebuffer.remove(prestart, preend);
                        }
                        if (pos - start > 10 && !sourcebuffer.updating) sourcebuffer.remove(0, pos - 3);
                        if (end - pos > 10 && !sourcebuffer.updating) sourcebuffer.remove(0, end - 3);
                    }
                    pre_pos = pos;
                }
            }
        }.bind(this);

        this.ws.onmessage = function(e) {
            this.streamTime = Date.now();
            if (typeof e.data == "string") {
                this.handleCommand(e.data);
                return;
            }
            if (firstMessage) {
                firstMessage = false;
                let moov = e.data;
                let mp4Box = new MP4Box;
                mp4Box.onReady = demux_moov;
                moov.fileStart = 0;
                mp4Box.appendBuffer(moov);
            }
            try {
                this.frameQueue.push(e.data);
                if (!sourcebuffer || sourcebuffer.updating) return;
                if (this.frameQueue.length === 1) {
                    sourcebuffer.appendBuffer(this.frameQueue.shift());
                } else {
                    let byte_length = 0;
                    for (const qnode of this.frameQueue) {
                        byte_length += qnode.byteLength;
                    }
                    let mp4buf = new Uint8Array(byte_length);
                    let offset = 0;
                    for (const qnode of this.frameQueue) {
                        let frame = new Uint8Array(qnode);
                        mp4buf.set(frame, offset);
                        offset += qnode.byteLength;
                    }
                    sourcebuffer.appendBuffer(mp4buf);
                    this.frameQueue.splice(0, this.frameQueue.length);
                }
            } catch (e) {
                console.error(e);
                this.loss();
            }
        }.bind(this);

        this.ws.onopen = function(e) {
            console.log("WebSocket connected!");
            this.initStreamAlive();
        }.bind(this);

        this.ws.onclose = function(e) {
            console.log("WebSocket closed!");
        }.bind(this);

        this.ws.onerror = function(e) {
            console.error(e);
            this.loss();
        }.bind(this);
    }

    // Destroy
    destroy() {
        try {
            this.singleKey.destroy();
            this.turboKey.destroy();
            this.keyBind.destroy();
            this.weatherDestroy();
        } catch (e) {}
        try {
            clearInterval(this.streamTimer);
            clearInterval(this.heartbeatTimer);
        } catch (e) {}
        try {
            this.ws && this.ws.close();
        } catch (e) {}
    }

}

export default UEPlayer;
