import "./Agreement.css";
function UserAgreement() {
    return (
        <div className="text-white w-[45rem] m-auto py-10 space-y-8 agreement-container">
            <h2 className="text-3xl text-center font-semibold">User Agreement</h2>
            <p className="text-xl">This BETA TEST AGREEMENT (the “Agreement”) is by and between Cybever, Inc. and its affiliates (collectively, “Cybever, Inc.”) and you (“Licensee” or “You”).</p>
            <p>1. <span>“Beta Product”</span>  shall mean the software, services, and/or features, including any accompanying documentation or related materials, that are designated as beta or pre-release versions by Cybever Inc. and provided to You during the term of this Agreement.</p>
            <p>2. <span>License.</span> Cybever, Inc. grants to You a non-exclusive, non-transferable, non-sublicensable license to use the Beta Product solely for the purposes of testing, research, and evaluation. You shall not modify, disassemble, decompile, reverse engineer, rent, lease, loan, transfer, or make copies of the Beta Product except as explicitly permitted by this Agreement.</p>
            <p>3. <span>Ownership.</span> All title, interest, and ownership rights in and to the Beta Product, including any associated documentation and any improvements, modifications, and enhancements, are and shall remain the exclusive property of Cybever Inc. Except for those rights expressly granted herein, no other rights are granted, either express or implied, to You.</p>
            <p>4. <span>Confidentiality.</span> The Beta Product, the fact of its existence, all accompanying documentation, and all information disclosed by Cybever, Inc. to You hereunder or otherwise in connection with the Beta Product, including without limitation performance data, features and other information relating to or obtained from the Beta Product, is “Confidential Information” of Cybever, Inc.. You will not use the Confidential Information except as necessary under this Agreement, and will not disclose any portion of the Confidential Information to any other person or entity. You will use all reasonable steps to protect the Confidential Information from unauthorized use or disclosure. Confidential Information does not include information that: (1) was rightfully known by You at the time of disclosure without an obligation of confidentiality, (2) is lawfully obtained by You from a third party without restriction on use or disclosure, or (3) is or becomes generally known to the public through no fault or breach of this Agreement by You.</p>
            <p>5. <span>Termination.</span> This Agreement will continue in effect until terminated in accordance with this Section 5.  You may terminate this Agreement at any time by returning or destroying any and all Confidential Information, including any Pre-Release Software, that is in Your possession or control.  At Cybever’s request, You agree to provide certification of Your compliance with the foregoing requirements upon any termination.  Cybever may terminate this Agreement at any time, with or without cause, immediately upon written notice to You, and may terminate this Agreement immediately for any breach of the confidentiality provisions set forth herein.  Within seven (7) days of Your receipt of Cybever’s termination notice, or earlier if requested by Cybever, You will return, cease all use of, and/or destroy the Pre-Release Software and all other Confidential Information as provided in this Section.  Following termination of this Agreement or an individual seeding project for any reason, the restrictions of Sections 2-4 and 6-9 will continue to bind the parties.  The term of your license to use the Beta Product granted under Section 2 of this Agreement shall commence upon your installation or use of the Beta Product. This Agreement terminates upon the earlier of (a) the commercial release of the Beta Product or (b) notice from Cybever Inc. The Licensee must comply with all requirements, including the return or destruction of materials, upon termination.</p>
            <p>6. <span>Data & Feedback.</span> You agree that Cybever, Inc. may obtain information and data (“Feedback”) from You in connection with Your registration, installation, and use of the Beta Product including, without limitation, personal information. Cybever Inc. may also collect and process technical and performance information about Your use of the Beta Product. This information may be used to support and troubleshoot issues, provide updates, analyze trends, and improve Cybever Inc. products or services.. Cybever Inc. is allowed to use and share such data with its partners for purposes related to the Beta Product, in compliance with applicable data protection laws. You hereby consent to Cybever, Inc. processing and transferring this information, including, if any, personal information, in conformity with the 
            <a href="https://www.cybever.ai/privacypolicy" target="_blank">Cybever, Inc. Privacy Policy</a>
             as updated from time to time and handled in accordance with applicable data protection laws. Further, You shall provide Feedback to Cybever, Inc. concerning the functionality and performance of the Beta Product from time to time as reasonably requested by Cybever, Inc., including, without limitation, identifying potential errors and improvements. You will also be invited to complete surveys regarding the Cybever, Inc. devices. Feedback provided to Cybever, Inc. through any of the foregoing methods in connection with the Beta Product may be used by Cybever, Inc. and its partners to improve or enhance products, services and/or features and, accordingly, Cybever, Inc. shall have a non-exclusive, perpetual, irrevocable, royalty-free, worldwide right and license to use, reproduce, disclose, sublicense, distribute, modify and otherwise exploit such Feedback without restriction.</p>
            <p>7. THE BETA PRODUCT MAY CONTAIN ERRORS AND IS PROVIDED FOR LIMITED EVALUATION ONLY. THE BETA PRODUCT IS PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. CYBEVER, INC. SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, NONINFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.</p>
            <p>8. THE TOTAL LIABILITY OF CYBEVER INC. ARISING OUT OF OR RELATED TO THIS AGREEMENT SHALL NOT EXCEED THE AMOUNT PAID BY YOU TO CYBEVER INC. UNDER THIS AGREEMENT, IF ANY, OR $100, WHICHEVER IS GREATER. IN NO EVENT SHALL CYBEVER, INC. HAVE LIABILITY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</p>
            <p>9. <span>Miscellaneous.</span> This Agreement shall be governed by and construed in accordance with the laws of the State of California, USA, excluding its conflict of laws principles. For Licensees located outside the United States, the Agreement shall be governed by the laws of the Licensee's country of residence, provided such laws do not conflict with the laws of the State of California. In the event of a conflict, the laws of the State of California shall prevail, except where such conflict pertains to mandatory provisions of the law of the Licensee's country that cannot be waived.</p>
        </div>
    );
}
export default UserAgreement;