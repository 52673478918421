import React from 'react';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  imgSrc: string;
  fallbackSrc: string;
}

const ImageComponent: React.FC<ImageProps> = ({ imgSrc, fallbackSrc, alt, ...props }) => {
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = e.target as HTMLImageElement;
    if (target.src !== fallbackSrc) {
      target.src = fallbackSrc;
    }
  };

  return (
    <img
      src={imgSrc}
      alt={alt}
      onError={handleError}
      {...props}
    />
  );
};

export default ImageComponent;
