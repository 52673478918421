import "./AssetsLibrary.css";

function AssetsLibrary(){
    return (
        <div>
            AssetsLibrary
        </div>
    );
};

export default AssetsLibrary;