import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getLocalToken } from '@/lib/utils'
// import {AppDispatch} from "../store";

export interface UserState {
    token: string
}

const initialState: UserState = {
    token: getLocalToken() || "",
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },
        removeToken: (state) => {
            state.token = "";
        }
    },
})

// Action creators are generated for each case reducer function
export const { setToken, removeToken } = userSlice.actions

export default userSlice


// export const fetchToken = () => {
//     return async (dispatch: AppDispatch) => {
//         dispatch(setToken(""))
//     }
// };

