import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import PreparedScene from "@/components/PreparedScene";
import RandomImage from "@/components/RandomImage/RandomImage";
import TerrainSize from "@/components/TerrainSize/TerrainSize";
import { defaultMapgenImgs } from "@/lib/data";
import MapGen from "@/mapgen";
import param from "@/mapgen/config/index.ts";
import { createTask } from "@/service/taskService";

import "./Mapgen.css";

import { useMount } from "ahooks";
import { message } from "antd";
import cx from "classnames";

import { isAxiosError } from "axios";

import {
  DEFAULT_MAP_SIZE,
  MAP_STYLE_TERRAIN,
  MAP_STYLE_TOWN,
  MAP_ZOOM,
  MAP_ZOOM_SIZE,
  ZOOM_500,
  ZOOM_5000,
} from "./constants";
import backIcon from "@/assets/back.png";
import MapgenTool from "@/components/MapgenTool";
import { MapgenSelect } from "@/types";
import { DEFAULT_MAPGEN_SELECT } from "@/components/MapgenTool/constants";

function Mapgen() {
  const [imgs, _] = useState(defaultMapgenImgs);
  const [zoom, setZoom] = useState<number>(ZOOM_5000);
  const [mapgen, setMapgen] = useState<MapGen | null>(null);
  const [mapgenSelect, setMapgenSelect] = useState<Partial<MapgenSelect>>(
    DEFAULT_MAPGEN_SELECT
  );
  const [baseImg, setBaseImg] = useState<string>();
  const [hideRedMap, setHideRedMap] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  useMount(() => {
    const mapgen = new MapGen("mapgen4", {
      zoom: zoom,
      paintOptions: {},
    });
    mapgen.onReady().then(() => {
      mapgen.render();
      mapgen.startPainting(zoom, (message: string) => {
        // setWarningMessage(message);
        // setWarningVisible(true);
        messageApi.open({
          type: "warning",
          content: message,
        });
      });
      setMapgen(mapgen);
    });

    return () => {
      mapgen.dispose();
    };
  });

  const saveRedImage = (): Promise<{
    blobRed: Blob;
    blobRedCut: Blob | undefined;
  }> => {
    return new Promise((reslove) => {
      if (!mapgen) return;
      const { renderer } = mapgen;
      console.log("renderer.getMountainHeight()", renderer.getMountainHeight());
      renderer.generateHeightMap();
      renderer.screenshotCallback = () => {
        renderer.screenshotCanvas.toBlob((blobRed: Blob | null) => {
          if (!blobRed) return;
          renderer.generateOriginalMap();

          const url = URL.createObjectURL(blobRed);
          // const link = document.createElement("a");
          // link.href = url;
          // link.download = "screenshot.png"; // 设置下载的文件名
          // link.click();
          // URL.revokeObjectURL(url);

          // if (zoom === 0.208) {
          //   console.log("in zoom");
          //   reslove(blob);
          //   // const link = document.createElement("a");
          //   // link.href = url;
          //   // link.download = "screenshot.png"; // 设置下载的文件名
          //   // link.click();
          //   // URL.revokeObjectURL(url);
          //   return;
          // }
          if (zoom === ZOOM_500) {
            const redInfoCanvas = document.createElement("canvas");
            const renderCanvas = document.querySelector("#mapgen4")!;
            redInfoCanvas.width = renderCanvas.clientWidth;
            redInfoCanvas.height = renderCanvas.clientHeight;
            const ctx = redInfoCanvas.getContext("2d");
            const img = new Image();
            img.onload = function () {
              // 计算 offset
              const scale = zoom * 5;
              const dOffset = ((1 - scale) / 2) * renderCanvas.clientWidth;
              const dSize = renderCanvas.clientWidth * scale;
              ctx?.drawImage(img, dOffset, dOffset, dSize, dSize);
              redInfoCanvas?.toBlob((blobRedCut: Blob | null) => {
                if (!blobRedCut) return;
                reslove({ blobRed, blobRedCut });
                // const url_tmp = URL.createObjectURL(blob);
                // const link = document.createElement("a");
                // link.href = url_tmp;
                // link.download = "screenshot.png"; // 设置下载的文件名
                // link.click();
                // URL.revokeObjectURL(url_tmp);
              });
            };
            img.src = url;
          } else {
            reslove({ blobRed, blobRedCut: undefined });
          }
        });
      };
    });
  };

  const saveOriginalImage = (): Promise<Blob> => {
    return new Promise((reslove) => {
      if (!mapgen) return;
      const { renderer } = mapgen;
      renderer.updateView(param.render);
      renderer.setScreenshotCallback(() => {
        renderer.getScreenshotCanvas().toBlob((blob: Blob | null) => {
          if (!blob) return;
          reslove(blob);
        });
      });
      // mapgen?.renderer.screenshotCallback = () => {
      //   mapgen?.renderer.screenshotCanvas.toBlob((blob: any) => {
      //     reslove(blob);
      //     // const url_tmp = URL.createObjectURL(blob);
      //     // const link = document.createElement("a");
      //     // link.href = url_tmp;
      //     // link.download = "screenshot.png"; // 设置下载的文件名
      //     // link.click();
      //     // URL.revokeObjectURL(url_tmp);
      //   });
      // };
    });
  };

  // 处理随机按钮点击事件
  const clickRandomBtn = async () => {
    // window.location.reload();
    mapgen?.reload();

    // render.updateView(param.render);
    // console.log({ mapgen });
    // // 生成4个新的唯一ID
    // const ids = Array.from({ length: 4 }, () => uuidv4());
    // let response,
    //   urls: Array<string> = [];
    // for (let i = 0; i < imgs.length; i++) {
    //   try {
    //     response = await randomMap();
    //     urls.push(await loadReader(response?.data));
    //   } catch (error) {
    //     break;
    //   }
    // }
    // // 使用新生成的ID创建新的图片数组
    // const newImgs = ids.map((id, index) => ({
    //   src: urls[index],
    //   id,
    // }));
    // // 更新图片状态
    // setImgs(newImgs);
  };

  // 处理地形大小变化
  // size: 新的地形大小
  const terrainSizeHandler = async (size: number) => {
    if (Object.keys(MAP_ZOOM).includes(size.toString())) {
      const zoom = MAP_ZOOM[size];
      console.log({ zoom });
      setZoom(zoom);
      mapgen?.painting.clearVillage();
      mapgen?.renderer.setZoom(zoom);
      mapgen?.renderer.updateView(param.render);
      if (mapgenSelect.size !== MAP_ZOOM_SIZE[size]) {
        setMapgenSelect((preVal) => {
          return { ...preVal, size: MAP_ZOOM_SIZE[size] };
        });
      }
    }

    // const mapgen = new MapGen("mapgen4", {
    //   zoom: zoom,
    // });

    // mapgen.onReady().then(() => {
    //   mapgen.render();
    //   const action = mapgen.startPainting();

    //   //   setMapgen(mapgen);
    //   setPaint(action);
    //   setRender(mapgen.getRender());
    // });
  };

  const loadReader = async (blob: Blob) => {
    return new Promise((reslove) => {
      const url = URL.createObjectURL(blob);
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setBaseImg(url);
        setHideRedMap(true);
        reslove(true);
      }
    });
  }

  // 处理生成风格的操作
  // name: 风格名称
  const generateStyleHandler = async (terrain: string, town: string) => {
    //console.log(terrain, town);
    const blob: Blob = await saveOriginalImage();
    await loadReader(blob);
    const res = await saveRedImage();
    const { blobRed, blobRedCut } = res as {
      blobRed: Blob;
      blobRedCut: Blob | undefined;
    };
    await generateModel(blob, blobRed, blobRedCut, terrain, town, zoom);
  };

  const getMapSizeByZoom = (value: number) => {
    return Number(Object.keys(MAP_ZOOM).find(key => MAP_ZOOM[+key] === value));
  }

  const generateModel = async (
    blob: Blob,
    blobRed: Blob,
    blobRedCut: Blob | undefined,
    terrain: string,
    town: string,
    zoom: number
  ) => {
    // console.log(town);
    // console.log("zoom",zoom);
    const mountain_height = mapgen?.renderer.getMountainHeight() || 60;
    const map_size = getMapSizeByZoom(zoom) || DEFAULT_MAP_SIZE;
    const jsonObject = {
      village: mapgen?.painting.getVillagePositions(),
      map_type: 2, // 1: color map, 2: map gen
      map_style_terrain: MAP_STYLE_TERRAIN[terrain], // 1 Wetland,2 Glacier,3 Plateaus
      map_style_town: MAP_STYLE_TOWN[town], // 1 Water village,2 Medieval village,3 Fantasy village, 4 Campsite
      map_size,
      mountain_height,
    };
    const jsonString = JSON.stringify(jsonObject);
    const jsonBlob = new Blob([jsonString], { type: "application/json" });
    const formData = new FormData();

    formData.append("files", blob, "map.png");
    formData.append(
      "files",
      blobRed,
      `map_h.png`
    );

    if (blobRedCut) {
      formData.append("files", blobRedCut, "map_h_cut.png");
    }
    formData.append("files", jsonBlob, "data.json");

    try {
      const response = await createTask(formData, 2);
      if (response.status === 200) {
        navigate("/home/preview", {
          state: {
            param,
            pos: mapgen?.painting.getVillagePositions(),
            task_id: response.data.task_id,
            from: "mapgen",
            size: map_size,
            biome: terrain,
            town: town,
          },
        });
      }
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.status === 403) {
          // setWarningVisible(true);
        }
      }
      return;
    } finally {
      setHideRedMap(false);
    }
  };

  return (
    <div className="pb-6">
      <div>
        <div className=" flex items-center">
          <Link to="/home/scene" className="flex items-center cursor-pointer">
            <img src={backIcon} alt="back" width={14} height={14} />
            <span className="ml-2 text-link">Back</span>
          </Link>
          <h2 className=" ml-10">Create a new scene</h2>
        </div>
      </div>
      <div className="flex mt-4 flex-col sm:flex-row">
        <div className="space-y-4 flex-1">
          <RandomImage
            serialNumber={1}
            isDisable={false}
            imgList={imgs}
            clickImg={(id) => {
              console.log("id:", id);
            }}
            clickRandomBtn={clickRandomBtn}
          />

          <TerrainSize
            serialNumber={2}
            isDisable={false}
            callback={terrainSizeHandler}
            options={[
              { size: 500, label: "500 * 500m", iconSize: "w-6 h-6" },
              { size: 750, label: "750 * 750m", iconSize: "w-7 h-7" },
              { size: 1000, label: "1000 * 1000m", iconSize: "w-8 h-8" },
              { size: 5000, label: "5000 * 5000m", iconSize: "w-9 h-9" },
            ]}
            defaultSize={5000}
          />

          <PreparedScene
            serialNumber={3}
            clickImgHandler={generateStyleHandler}
          />
        </div>
        <div
          id="ui"
          className="board sm:ml-6 w-[600px] flex flex-col max-w-[90rem] text-sm relative overflow-hidden"
        >
          <div id="map" className={cx({"!absolute bottom-full left-full": hideRedMap})}>
            <canvas id="mapgen4" width="2048" height="2048" />
          </div>
          <img src={baseImg} className={cx('w-full',{"!hidden": !hideRedMap})} />

          <MapgenTool
            mapgenSelect={mapgenSelect}
            setMapgenSelect={setMapgenSelect}
          />

          {/* <div id="sliders" className="">
            <button id="button-reset">Reset</button>
          </div> */}
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
export default Mapgen;
