import { convertToTime } from "@/lib/utils";
import { forwardRef, useState } from "react";



const WeatherControl = forwardRef<HTMLDivElement>((_, parentRef) => {
    const [time, setTime] = useState(12);
    const [weatherIntensity, setWeatherIntensity] = useState(1);
    const [windIntensity, setWindIntensity] = useState(1);

    return (
        <div className="absolute right-0 top-0 text-sm" ref={parentRef}>
            <details>
                <summary>Weather Control</summary>
                <div className="flex items-center space-x-2 time">
                    <span>Time :</span>
                    <input type="range" min="0" step="0.5" max="23" value={time} className="flex-1" onChange={(e) => { setTime(parseFloat(e.target.value)) }} />
                    <b className=" w-20 text-center">{convertToTime(time)}</b>
                </div>
                <div className="flex items-center space-x-4 weather">
                    <div className="flex space-x-1">
                        <span>Weather :</span>
                        <p className="flex items-center">
                            <input type="radio" name="option" value="rain" id="rain" />
                            <label htmlFor="rain">rain</label>
                        </p>
                        <p className="flex items-center">
                            <input type="radio" name="option" value="snow" id="snow" />
                            <label htmlFor="snow">snow</label>
                        </p>
                        <p className="flex items-center">
                            <input type="radio" name="option" value="other" id="sunny" />
                            <label htmlFor="sunny">sunny</label>
                        </p>
                    </div>
                    <div className="flex items-center space-x-2 weather-intersity">
                        <span>Intensity :</span>
                        <input type="range" min="0" max="10" value={weatherIntensity} onChange={(e) => { setWeatherIntensity(parseInt(e.target.value)) }} />
                        <i className=" w-5">{weatherIntensity}</i>
                    </div>
                </div>
                <div className="flex items-center space-x-4 wind">
                    <div className="flex space-x-1">
                        <span>Wind :</span>
                        <p className="flex items-center">
                            <input type="checkbox" id="enable" />
                            <label htmlFor="enable">enable</label>
                        </p>
                    </div>
                    <div className="flex-1 flex items-center space-x-2 wind-instensity">
                        <span>Intensity :</span>
                        <input type="range" min="0" max="10" className="flex-1" value={windIntensity} onChange={(e) => { setWindIntensity(parseInt(e.target.value)) }} />
                        <i className=" w-5">{windIntensity}</i>
                    </div>
                </div>
                <button className="py-1 px-4 bg-slate-500 rounded-md mt-1">Confirm</button>
            </details>
        </div>
    )
});

export default WeatherControl;