import { Pos } from "@/types";
import villageIcon from "@/assets/village1.svg";

class VillageManager {
    // @ts-ignore
    private mapEl: HTMLElement;
    private villages: Array<Pos> = [];
    private onWarning?: (message: string) => void;

    public init(mapEl: HTMLElement, onWarning?: (message: string) => void) {

        this.mapEl = mapEl;
        this.onWarning = onWarning;
       // this.mapEl.addEventListener('click', this.addVillageHandler);
    }

    public addVillageHandler = (event: MouseEvent) => {
        event.stopPropagation();
        const bounds = this.mapEl.getBoundingClientRect();
        const coords = [
            (event.x - 10 - bounds.left) / bounds.width,
            (event.y - 10 - bounds.top) / bounds.height,
        ];

        if(this.villages.length >= 3) {
            this.onWarning?.('Up to 3 town locations can be added.');
            return;
        }

        const timestamp = Date.now().toString();
        const img = this.createVillageImage(event.y, coords[0], timestamp);
        this.mapEl.appendChild(img);
        // @ts-ignore
        this.villages.push({ vid: timestamp, x: coords[0], y: coords[1] });
    }

    private createVillageImage(y: number, x: number, id: string): HTMLImageElement {
        const img = new Image();
        img.src = villageIcon;
        img.width = 20;
        img.style.position = "absolute";
        img.style.left = `${(x * 100).toFixed(4)+1}%`;
        img.style.top = `${y-65}px`;
        img.setAttribute("imgId", id);
        return img;
    }

    public enableVillageRemoval() {
        const imgs = this.mapEl.querySelectorAll('img');
        imgs.forEach(img => {
            img.setAttribute("enable", "true");
            img.onclick = () => this.removeVillage(img);
        });
    }

    private removeVillage(img: Element) {
        const timestamp = img.getAttribute("imgId");
        img.remove();
        // @ts-ignore
        this.villages = this.villages.filter(v => v.vid !== timestamp);
    }

    public clear() {
        this.villages = [];
        const imgs = this.mapEl.querySelectorAll('img');
        imgs.forEach(img => img.remove());
    }

    public getVillages(): Array<Pos> {
        return this.villages;
    }
}

export default new VillageManager();