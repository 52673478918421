import { ChangeEvent, useEffect, useState } from 'react';
import { Input, message, Modal, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { checkWaitList, saveWaitList } from '@/service/taskService';

function WaitList({ isOpen }: { isOpen: number }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [role, setRole] = useState("3D artist");
    const [form, setForm] = useState({ "name": localStorage.getItem("username") || "", "organization_name": "", "memo": "" });

    useEffect(() => {
        async function check(){
            if(isOpen){
                let checkReslut = await checkWaitList();            
                let name = checkReslut.data.hasOwnProperty("name");
                if (name) {
                    message.warning("You are now on our waiting list, thanks");
                    setIsModalOpen(false);
                    return
                };
    
                setIsModalOpen(true);
            };
        };
        check();
    }, [isOpen]);

    const handleOk = async () => {
        try {
            let result = await saveWaitList({ ...form, "role": role });
            setIsModalOpen(false);
            if (result.data.result === 1) {
                message.success("Successfully added to waiting list.");
                return
            }
           
            message.success("You are now on our waiting list, thanks");
        } catch (e) {
            console.log(e);
            setIsModalOpen(false);
            message.error("Application failed!");
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    const selectChange = (value: string) => {
        setRole(value);
    };

    const nameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, name: e.target.value });
    }

    const organizationChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, organization_name: e.target.value });
    }

    const textareaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setForm({ ...form, memo: e.target.value });
    }

    return (
        <Modal title="Please fill the form to gain early access to the full functionality." open={isModalOpen} onOk={handleOk} okText="Join now" onCancel={handleCancel}>
            <div>
                <label>Name:</label>
                <p>
                    <Input placeholder="Name" value={form.name} onChange={nameChange} />
                </p>
            </div>
            <div>
                <label>Organization name(optional):</label>
                <p>
                    <Input placeholder="Organization name" value={form.organization_name} onChange={organizationChange} />
                </p>
            </div>
            <div>
                <label>What's your role?(optional)</label>
                <div>
                    <Select
                        defaultValue="3D artist"
                        style={{ width: "100%" }}
                        onChange={selectChange}
                        options={[
                            { value: '3D artist', label: '3D artist' },
                            { value: 'Business / Marketing', label: 'Business / Marketing' },
                            { value: 'Concept Artist', label: 'Concept Artist' },
                            { value: 'Content Creator', label: 'Content Creator' },
                            { value: 'Developer', label: 'Developer' },
                            { value: 'Game Designer', label: 'Game Designer' },
                            { value: 'Graphic Designer', label: 'Graphic Designer' },
                            { value: 'Student and Faculty', label: 'Student and Faculty' },
                            { value: 'Technical Artist', label: 'Technical Artist' },
                            { value: 'UI/UE', label: 'UI/UE' },
                            { value: 'VFX Artist', label: 'VFX Artist' },
                            { value: 'Other', label: 'Other' },
                        ]}
                    />
                </div>
            </div>
            <div>
                <label>Why do you want to use this tool?(optional)</label>
                <p>
                    <TextArea rows={4} placeholder="do..." value={form.memo} onChange={textareaChange} />
                </p>
            </div>
        </Modal>
    );
}

export default WaitList;