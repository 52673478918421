// @ts-nocheck
'use strict';
// import Renderer from "./core/render.ts";
import Renderer from "./core/render.ts";
import Painting from "./core/painting.ts";
import  { makeMesh }  from "./algorithm/mesh.ts";
import param from "./config/index.ts";
import   Sliders  from "./plugin/sliders.ts";
import type { Mesh } from "./types.d.ts";
import "./assets/css/common.css";
import { buffer } from "stream/consumers";
 import MapWorker from "./service/index.ts";

interface MapGenOptions {
    zoom: number;
    paintOptions?: {
      onWarning?: (message: string) => void;
    };
  }

  interface WorkerMessage {
    param: typeof param;
    constraints: {
        size: number;
        constraints: any;
    };
    buffers: any;
}
interface WorkerResponse {
    elapsed: number;
    numRiverTriangles: number;
    buffers: {
        quad_elements: Int32Array;
        a_quad_em: Float32Array;
        a_river_xyuv: Float32Array;
    };
}

interface RenderPayload {
    mesh: typeof this.mesh;
    t_peaks: typeof this.t_peaks;
    param: typeof param;
}
interface MapGenOptions {
    zoom: number;
    plugins?: Plugin[];
    onError?: (error: Error) => void;
}

interface MeshData {
    mesh: any;  // 根据实际类型定义
    t_peaks: any;  // 根据实际类型定义
}

class MapGen{

    public renderer:Renderer;
    public painting:any ;
    protected mesh:Mesh;
    protected t_peaks:any;

    protected elapsedTimeHistory:[] = [];
    protected working:boolean = false;
    protected workRequested:boolean = false;
    protected worker:Worker | null = null;
    private el: string ;
    protected plugins :any[] = [];

    private isInitialized: boolean = false;
    private readyPromise: Promise<void>;
    private readyResolver!: () => void;
    private zoom :number;
    private mapWorker:MapWorker;

    constructor (el:string, options: MapGenOptions){
        // super()
        this.el = el;
        this.use( Sliders );
        this.readyPromise = new Promise((resolve) => {
            this.readyResolver = resolve;
        });
        this.painting = new Painting(`${this.el}`,"map");
        
        this.init(el,options);
    }

    private async init(el, options:MapGenOptions): Promise<void>{
        try{

            // basic config
            this.initializeBasicConfig(options);
            // mesh data
            await this.initializeMeshData();
            // renderer
            this.initializeRenderer(el);
            // worker
            this.initializeWorker();
            // plugins
           await this.initializePlugins();

            this.completeInitialization();
        }catch(e){
            console.error('MapGen initialization failed:', error);
            throw error;
        }

    }

    // basic config
    private  initializeBasicConfig(options: MapGenOptions):void {
        this.zoom = options.zoom;
       // this.plugins = options?.plugins || [];
    }

    // mesh data
    private async initializeMeshData(): Promise<void> {
        const { mesh, t_peaks } = await makeMesh();
        this.mesh = mesh;
        this.t_peaks = t_peaks;
    }

    // renderer
    private initializeRenderer(el: string): void {
        if(!el){
            throw new Error('element container is required');
        }
        if (!this.mesh) {
            throw new Error('Mesh data not initialized');
        }
        this.renderer = new Renderer(el, this.mesh,this.painting);
    }

    private initializeWorker(): void {
        if (!this.renderer) {
            throw new Error('Renderer not initialized');
        }
        this.mapWorker = new MapWorker(
            this.renderer,
            this.generate.bind(this)
        );
    }

    private async initializePlugins(): Promise<void> {
        if (this.plugins.length > 0) {
            await Promise.all(this.plugins.map(plugin => plugin.onInit?.(this)));
            // await Promise.all(
            //     this.plugins.map(plugin => 
            //         plugin.onInit?.(this).catch(error => {
            //             console.warn(`Plugin initialization failed:`, error);
            //             return null;
            //         })
            //     )
            // );
        }
    }

    private completeInitialization(): void {
        this.isInitialized = true;
        this.readyResolver();
    }   

    use(plugin){
        plugin.init?.(this);
        this.plugins.push(plugin);
    }

    public startPainting(zoom , onWarning?: (message: string) => void) :PaintEventHandler | null{
        
        if (!this.isInitialized ) {
            console.warn('MapGen is not ready for painting');
            return null;
        }
        this.painting.initEvent(
             this.renderer , 
             this.generate.bind(this),
             onWarning
        );
        // Painting.reset("reset");
        // return action;
    }

    public generate(seed?:number) {
        if (!this.mapWorker.isWorking()) {
            if(seed){
                param.elevation.seed = seed;
            }
            this.mapWorker.startWork();
            this.painting.setElevationParam(param.elevation);
            const payload: GeneratePayload = {
                param,
                constraints: {
                    size: this.painting.CANVAS_SIZE,
                    constraints: this.painting.elevation,
                },
                ...this.getTransferBuffers()
            };
            const transferBuffers = this.getTransferBufferList();
            this.mapWorker.getWorker().postMessage(payload, transferBuffers);
        } else {
            this.mapWorker.requestWork();
        }
    }

    private getTransferBuffers() {
        const { geometryBuffers } = this.renderer;
        return {
            quad_elements_buffer: geometryBuffers.quad_elements.buffer,
            a_quad_em_buffer: geometryBuffers.a_quad_em.buffer,
            a_river_xyuv_buffer: geometryBuffers.a_river_xyuv.buffer
        };
    }



    private getTransferBufferList(): ArrayBuffer[] {
        const { geometryBuffers } = this.renderer;
        return [
            geometryBuffers.quad_elements.buffer,
            geometryBuffers.a_quad_em.buffer,
            geometryBuffers.a_river_xyuv.buffer
        ];
    }
    public async onReady(): Promise<void> {
        await this.readyPromise;
    }

    public render(){
        if (!this.isInitialized ) {
            console.warn('MapGen is not ready for Render');
            return null;
        }

        if (!this._validateRenderParams()) {
            console.error('Invalid render parameters');
            return;
        }

        try {
            // prepare render data
            const payload: RenderPayload = {
                mesh: this.mesh,
                t_peaks: this.t_peaks,
                param
            };

            // send render message
            if (this.mapWorker?.getWorker()) {
                this.mapWorker.getWorker().postMessage(payload);
                
                this.generate();
            } else {
                throw new Error('Worker not available');
            }

        } catch (error) {
            console.error('Render failed:', error);
            this.handleRenderError(error);
        }
    }

    public reload(){
        const seed = Math.floor(Math.random() * (1 << 30));
        this.generate(seed);
    }
    public setZoom(zoom:number){
        this.zoom = zoom;
        this.renderer.setZoom(zoom);
    }
    
    private _validateRenderParams(): boolean {
        return !!(
            this.mesh &&
            this.t_peaks &&
            this.mapWorker?.getWorker()
        );
    }

    public update(){

    }

    public dispose(): void {
        try {
            // dispose worker
            if (this.mapWorker) {
                this.mapWorker.dispose();
            }

            // dispose renderer
            if (this.renderer) {
                this.renderer.dispose();
            }

            this.plugins.forEach(plugin => plugin.onDestroy?.(this));
            this.plugins = [];

            // dispose other resources
            // ...

        } catch (error) {
            console.error('Error disposing MapGen:', error);
        }
    }

    public getRender(){
        return this.renderer;
    }

}

export default MapGen;