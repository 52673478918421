export const PHASES = {
    ELEVATION: 'elevation',
    BIOMES: 'biomes',
    RIVERS: 'rivers',
    RENDER: 'render',
    OTHERS: 'others'
} as const;

export const VISIBLE_PHASES = [
    PHASES.ELEVATION,
    PHASES.RIVERS,
    PHASES.RENDER,
    PHASES.OTHERS
] as const;



export type PhaseType = typeof PHASES[keyof typeof PHASES];