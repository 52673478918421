import "./Collections.css";

function Collections(){
    return (
        <div>
            Collections
        </div>
    );
}

export default Collections;